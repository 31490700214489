import Request from "../services/uspay"
export default {
    async getUrl (data) {
        let result;
		try {
            result = await Request.getUrl(data)
		} catch (error) {
			console.warn(error)
			result = false;
		}
		return result ? result.data : false;
	},
	async checkPay (data) {
        let result;
		try {
            result = await Request.checkPay(data)
		} catch (error) {
			console.warn(error)
			result = false;
		}
		return result ? result.data : false;
	},
}