import React, { useState , useEffect }  from "react";
import { Table, Badge , Button, Modal , Form} from "react-bootstrap"
import Api from "../../modules/api"

const OrderTable = ({data,url, logout}) => {
    const [show, setShow] = useState(false);
    const [cancelReasons, setCancelReasons] = useState([]);
    const [orderId, setOrderId] = useState(-1)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [selectedReason, setSelectedReason] = useState("1")
    const [orderData, setOrderData] = useState(data)

    const stateName = [
        "Pendiente",
        "Confirmado",
        "Cancelado",
        "Entregado",
        "Pagado",
        "Aceptado",
        "Recogido",
        "Retrasado",
        "Rechazado",
     ];
  
     const stateBag = [
        "warning light",
        "warning",
        "danger",
        "success",
        "success light",
        "info light",
        "info",
        "dark",
        "danger light",
     ];

     useEffect(()=>{
        setOrderData(data);
     },[data])

     useEffect(() => {
        Api.getDeliveryCancelReason().then((response)=>{
            if(response && response.result==="success")
            {
                setCancelReasons(response.data)
            }
        })
     },[])
  
    const cancelOrder = ({id , index}) =>{
        setOrderId(id);
        setSelectedIndex(index);
        setShow(true);
    }

    const confirmCancelOrder = () =>{
        let FormData = require('form-data');
        let formdata = new FormData();
        const userData = localStorage.getItem('userData')
        if(userData){
            const user = JSON.parse(userData); 
            formdata.append("user_id" ,user.userId);
            formdata.append("order_id" , orderId);
            formdata.append("cancel_reason_id" , selectedReason);
            Api.cancelDelivery(formdata).then(res=>{
                if(res )
                {
                    alert(res.message);
                    if(res.result && res.result.toLowerCase() === "success")
                    {
                        const newdata = orderData;
                        newdata.splice(selectedIndex, 1); 
                        setOrderData(newdata);
                        setShow(false);
                    }
                }
            }).finally(()=>{
    
            })
        }

    }
    return (
        <Table responsive className="order-list items-table">
            <thead>
                <tr>
                    <th>Datos de Orden</th>
                    <th>Dirección</th>
                    <th>Estado</th>
                    <th>Acción</th>
                </tr>
            </thead>
            {logout || orderData.length=== 0 ? 
                <tbody>
                    <tr>
                        <td className="media-body justify-content-center">
                            { <p className="mb-0 text-warning">No hay envios.</p>}
                        </td>
                    </tr>	
                </tbody>:
                <tbody>
                {orderData.map((item, i) => 
                    {
                        //const status_name_color = getStatusNameColor(parseInt(item.delivery_status));
                        const profile_image = item.profile_image && item.profile_image !== "" ? item.profile_image:"52e164499740b60ce6e0fdaefd2c9372.jpg";
                        
                        return (
                            <tr>
                                <td >
                                    <div className="media">
                                        <img className="mr-3 rounded-circle" width="85" height="85" src={`${url}${profile_image}`} alt="perfil" />
                                        <div className="media-body">
                                            <small className="mt-0 mb-1 font-w500">
                                                <p className="text-primary" style={{marginBottom:2}}>{item.delivery_date}</p>
                                            </small>
                                            <h5 className="mt-0 mb-2">
                                                <p className="text-black" style={{marginBottom:0}}>ID - {item.order_id}</p>
                                            </h5>
                                            <div className="fs-14">
                                                <span className="text-dark">Conductor: {item.firstname ? item.firstname : "N/A"} {item.lastname}</span>
                                                {item.phone && <a href={`tel:504${item.phone}`}><i className="text-orange"><br/>Tlf.: {item.phone}</i></a>}
                                            </div>
                                        </div>
                                    </div>

                                </td>
                                <td >
                                    <div className="row">
                                        <p className="text-primary mb-0">Pik Origen: </p>
                                        <p className="mb-0">{item.pickupaddress}</p>
                                    </div>
                                    <div className="row">
                                        <p className="text-primary mb-0">Pik Destino: </p>
                                        <p className="mb-0">{item.dropoffaddress}</p>
                                    </div>
                                </td>
                                <td>
                                    <Badge variant={stateBag[item.delivery_status -1]}>{stateName[item.delivery_status -1]}</Badge>
                                </td>
                                {(item.delivery_status === "1" || item.delivery_status === "5" || item.delivery_status === "6") &&
                                    <td>
                                        <div className="d-flex">
                                            <Button variant="link" onClick={()=>{cancelOrder({id: item.order_id, index : i})}}>
                                                <i className="fa fa-close color-danger"></i>
                                            </Button>
                                            {/* {   item.delivery_status === "1" &&
                                                <Button variant="link" onClick={()=>{cancelOrder({id: item.order_id, index : i})}}>
                                                   <i class="fa fa-credit-card color-success"></i>
                                                </Button>
                                            } */}
                                        </div>
                                    </td>
                                }

                            </tr>	
                        )
                    }
                )}			
            </tbody>}
            <Modal show={show} onHide={()=> setShow(false)} className="fade">
                <Modal.Header closeButton>
                    <Modal.Title>Cancelar Pedido</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Seleccione el motivo de cancelación</p>
                    <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Motivo</Form.Label>
                            <Form.Control as="select" onChange={(event)=> {setSelectedReason(event.target.value)}}>
                                {
                                    cancelReasons.map((item,index)=>{
                                        return(
                                            <option key={item.id_delivery_cancel_reason} value={item.id_delivery_cancel_reason}>
                                                {item.reason}
                                            </option>
                                        );
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        {
                          selectedReason ==="4" &&  <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Describa su motivo aqui</Form.Label>
                                <Form.Control as="textarea" rows={3} />
                            </Form.Group>
                        }

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" onClick={()=> setShow(false)}>Cerrar</Button>
                    <Button variant="primary" onClick={()=> confirmCancelOrder()}>Guardar</Button>
                </Modal.Footer>
            </Modal>
        </Table>
    );

}
export default OrderTable;