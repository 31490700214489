import Request from "../services/pagadito"
export default {
    async connect () {
        let result;
		try {
            result = await Request.connect()
		} catch (error) {
			console.warn(error)
			result = false;
		}
		return result ? result.data : false;
	},
	async exec_trans (data) {
        let result;
		try {
            result = await Request.exec_trans(data)
		} catch (error) {
			console.warn(error)
			result = false;
		}
		return result ? result.data : false;
	},
	async get_status (data) {
        let result;
		try {
            result = await Request.get_status(data)
		} catch (error) {
			console.warn(error)
			result = false;
		}
		return result ? result.data : false;
	},
}