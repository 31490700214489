import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import history from "../../history";
import Api from "../../modules/api";
import {Button} from "react-bootstrap"
const ResetPassword = () => {
    const [data, setData] = useState({
        password:"",
        repeatPassword: "",
        email:"",
        userid: ""
     });
     const [isLoading, setLoading] = useState(false);
     useEffect(()=>{
        const url_string = window.location.href;
        const url = new URL(url_string);
        const token = url.searchParams.get("token");
        if(token!==null)
        {
           Api.checkForgetTime({userid: token}).then((response)=>{
               console.log(response)
               if(response && response.response){
                   const newdata = data;
                   newdata.email = response.email;
                   newdata.userid = token;
                   setData(newdata);
               }else{
                 swal("Alerta", "El Elance a expirado", "error");
                 history.replace('/login');
               }
           })
        }else{
            swal("Alerta", "El Elance a expirado", "error");
            history.replace('/login');
        }
     },[])
  
     const handleBlur = (e) => {
        const newData = { ...data };
        newData[e.target.name] = e.target.value;
        setData(newData);
     };

     const submitHandler = (e) => {
        e.preventDefault();
        let FormData = require('form-data');
        let form = new FormData();
        form.append('userid', data.userid);
        form.append('password', data.password);
        if(data.password === ""  || data.repeatPassword === ""){
            swal("Alerta", "Todos los campos son necesarios", "error");
        }else{
            if(data.password !== data.repeatPassword){
                swal("Alerta", "Las Contraseñas no coinciden", "error");
            }else{
                if(data.password.length<6){
                    swal("Alerta", "La nueva contraseña debe tener como minimo 6 carácteres", "error");
                }else{
                    setLoading(true)
                    Api.updatePassword(form).then((res)=>{
                        console.log(res)
                        if(res && res.result === "success"){
                            swal("Alerta", res.message, "success");
                            history.replace('/login');
                        }
                    }).catch( (error)=> {
                     swal("Alerta",error, "error");
                     console.log(error);
                  }).finally(()=>{
                     setLoading(false)
                  })
                }
            }
        }   
     }

    return (
        <div className="row justify-content-center  align-items-center h-80">
           <div className="col-md-6">
              <div className="authincation-content">
                 <div className="row no-gutters">
                    <div className="col-xl-12">
                       <div className="auth-form">
                          <h4 className="text-center mb-4">
                             Cambiar Contraseña
                          </h4>
                          <form
                             action=""
                             onSubmit={(e) => submitHandler(e)}
                          >
                            <div className="form-group">    
                                <label className="mb-1 text-primary">  
                                   <strong>
                                      Correo Electronico
                                   </strong> 
                                </label>
                                <input 
                                   type="email" 
                                   className="form-control text-primary" 
                                   name="email" 
                                   disabled
                                   value={data.email} 
                                   onChange={handleBlur}   
                                />
                             </div>
                             <div className="form-group">    
                                <label className="mb-1 text-primary">  
                                   <strong>
                                      Contraseña nueva
                                   </strong> 
                                </label>
                                <input 
                                   type="password" 
                                   className="form-control text-primary" 
                                   name="password" 
                                   value={data.password} 
                                   onChange={handleBlur}   
                                />
                             </div>
                             <div className="form-group">    
                                <label className="mb-1 text-primary">  
                                   <strong>
                                      Repetir Contraseña nueva
                                   </strong> 
                                </label>
                                <input 
                                   type="password" 
                                   className="form-control text-primary" 
                                   name="repeatPassword" 
                                   value={data.repeatPassword} 
                                   onChange={handleBlur}   
                                />
                             </div>
                             <div className="text-center">
                                <Button  
                                    type="submit"  
                                    className="btn btn-primary btn-block"  
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'ESPERE..' : 'CAMBIAR CONTRASEÑA'}
                                </Button>
                             </div>
                          </form>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     );
}

export default ResetPassword;