import React from "react";
import logo from "../../../images/logo.png";
import MapPicker  from "./MapPicker";
import Select from 'react-select'
import { colonias } from "../../../constants"

const PikDestino = (props) => {

   function handleChangeLocation (lat, lng){
      props.setDropoffLocation({latitude:lat, longitude:lng});
    }

    const setDeliveryData = (colonia) =>
    {
      const index = colonias.findIndex((item)=> {
         if(item.value){
            return item.value.trim().toLowerCase() === colonia.trim().toLowerCase();
         }
         return null;
      });
      if(index>=0){
         const coloniaSelects = colonias[index];
         const newDeliveryData = { ...props.dropOffData  };
         newDeliveryData.address = coloniaSelects.label;
         newDeliveryData.colonia = coloniaSelects;
         props.setDropOffData(newDeliveryData);
      }
    }

    const handleInputChange = (coloniaSelects) => {
      const newDeliveryData = { ...props.dropOffData };
      if(coloniaSelects){
         newDeliveryData.address = coloniaSelects.label;
         newDeliveryData.colonia = coloniaSelects;
      }else{
         newDeliveryData.colonia=null;
      }
      props.setDropOffData(newDeliveryData);
   };


   return (
      <section>
         <div className="row justify-content-center align-items-center mb-3">
            <img style={{borderRadius:10}} className="mr-2" src={logo} alt="" />
            <h5 className="text-center text-primary">Destino</h5>
         </div>
        <div className="form-group">
            <Select 
               value={props.dropOffData.colonia}
               options={colonias} 
               onChange={handleInputChange}
               name="colonias"
               isClearable
               isSearchable
               placeholder="Seleccione una Colonia"
               autoFocus
               theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                     ...theme.colors,
                     primary: '#2E2EF1',
                   },
                })}
            />
            {/* <input
                type="text"
                name="address" 
                placeholder="Dirección de entrega"
                value={props.dropOffData.address}
                className="form-control text-primary"
                onChange={props.handleBlurStep2}
            /> */}
        </div>
        <div className="form-group">
            <input
               type="textarea"
               name="detail" 
               placeholder="Dirección de entrega"
               value={props.dropOffData.detail}
               className="form-control text-primary"
               onChange={props.handleBlurStep2}
            />
         </div>
         <MapPicker 
            address={props.dropOffData.colonia} 
            updateCoords={handleChangeLocation} 
            setAddress={setDeliveryData}
            setValidArea={props.setValidArea}
         />
         <br/>
      </section>
   );
};

export default PikDestino;

