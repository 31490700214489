import React, { useState, useEffect } from "react"
import { apiKey} from "../../../services/maps"
import { GoogleMap , Marker, Polygon , useJsApiLoader } from '@react-google-maps/api';
import Geocode from "react-geocode";
import Api from "../../../modules/api"
import { Spinner } from "react-bootstrap"

const MapPicker = ({address, updateCoords, setAddress, draggable = true, height= '200px', setValidArea}) => {
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: apiKey
      })
    const [map, setMap] = React.useState(null)
    const [coords , setCoords] = useState({ lat: 14.0434647, lng: -87.2000473 })
    const [polygons, setPolygons] = useState([])
    const [origin, setOrigin] = useState("parent")
    const containerStyle = {
            height: height
        };

   useEffect(()=>{
    Geocode.setApiKey(apiKey);
    Geocode.setLanguage("es");
    Geocode.setLocationType("APPROXIMATE");
    Geocode.enableDebug();
   },[])

   useEffect(()=>{
    async function load(){
        // console.log({address})
        // console.log({origin})
        if(origin==="parent" && address !== null)
        {
            try {
                // const response = await Geocode.fromAddress(address+" Honduras Tegucigalpa"); usar en caso de enviar direccion y no colonia
                const { lat, lng } = address.latlng; //response.results[0].geometry.location;
                setCoords({lat, lng})
                updateCoords(lat, lng)
                //if(!validateArea())alert("Selección inválida");
            } catch (error) {
            }
        }
        setOrigin("parent");
    }
    load();
   },[address])

   const validateArea = (newcoords) =>{
        let isValid = true;//init
        for(const key in polygons)
        {
            const polygon = polygons[key];
            isValid = !window.google.maps.geometry.poly.containsLocation(newcoords.latLng, polygon.polygon);
            if(!isValid)
            break;
        }
        setValidArea(isValid);
        return isValid;
   }

   const onLoad = React.useCallback(function callback(map) {
        setMap(map)
        // console.log("loade")
        Api.listRestrictedAreas().then((response)=>{
            // console.log(response)
            if(response && response.result)
            {
                let areas = []
                for(const key in response.data)
                {
                    const polygon = response.data[key];
                    const LatLng = [];
                    const paths = [];
                    for(const j in polygon.coords)
                    {
                        LatLng.push( { 
                            lat: parseFloat(polygon.coords[j].lat),
                            lng: parseFloat(polygon.coords[j].long) 
                        })
                        paths.push( new window.google.maps.LatLng(
                            parseFloat(polygon.coords[j].lat),
                            parseFloat(polygon.coords[j].long)
                        ))
                    }
                    areas.push({ 
                        coords: LatLng, 
                        name:polygon.name, 
                        polygon: new window.google.maps.Polygon( {paths} )
                    })
                }
                setPolygons(areas)
            }
        })
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

   const setCoordsFromPicker = async(coords) =>{
        if(!validateArea(coords))alert("Selección inválida");
        setCoords({lat: coords.latLng.lat(), lng: coords.latLng.lng()})
        updateCoords(coords.latLng.lat(), coords.latLng.lng())
        const response = await Geocode.fromLatLng(coords.latLng.lat(), coords.latLng.lng());
        // console.log({response})
        let newAddress = "";
        try{
            newAddress = response.results[0].address_components[0].short_name;
            setOrigin(address === null ? "parent" : "child");
            setAddress(newAddress)
        } catch(ex){
            // setOrigin("parent");
        }

    }

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
      }
   
    return isLoaded ? (
        <GoogleMap
            options={{
                mapTypeControl: false,
                panControl: false,
                streetViewControl: false,
                zoomControl: true,
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            mapContainerStyle={containerStyle}
            center={coords}
            defaultZoom={15}
            zoom={15}>
            <Marker
                position={coords}
                draggable={draggable}
                onDragEnd={(coords) => setCoordsFromPicker(coords)}
            />
            {
                polygons.map((value,index)=>{
                    return(
                        <Polygon
                            key={index}
                            path={value.coords}
                            options={{ fillColor: "red", fillOpacity: 1 }}
                        />
                    );
                })
            }
        <></>
        </GoogleMap>
    ) : <Spinner />
};

export default React.memo(MapPicker)

