import React from "react";
import history from "../history";

/// React router dom
import { Router, Switch, Route } from "react-router-dom";
/// Css
// import "./index.css";
// import "./chart.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer"; 

/// Pages

import Login from "./auth/Login";
import SignUp from "./auth/SignUp";
import Forgot from "./auth/ForgotPassword";
import Order from "./delivery/Order";
import Delivery from "./delivery/Delivery";
import Orders from "./delivery/Orders";
import Profile from "./home/Profile";
import Thankyou from "./delivery/Thankyou";
import PaymentReceived from "./delivery/PaymentReceived"
import Contactus from "./contactus/Contactus";
import Reset from "./auth/ResetPassword"


const Markup = () => {
   const routes = [
        /// Auth
        { url: "login", component: Login },
        { url: "signup", component: SignUp },
        { url: "forgot", component: Forgot },
        { url: "reset-password", component : Reset},
    
        { url: "", component: Delivery },
        { url: "ordenes", component: Orders },
        { url: "detalle", component: Order },
        { url: "success" , component: Thankyou},
        { url: "payment-received" , component: PaymentReceived},
        { url: "perfil", component: Profile },

        { url: "contact", component: Contactus}

   ];

   return (
		
    <Router history={history} basename="/">
        <div id="main-wrapper" className="show">
            <Nav />
            <div className="content-body">
                <div className="container-fluid">
                    <Switch>
                        {routes.map((data, i) => (
                            <Route
                                key={i}
                                exact
                                path={`/${data.url}`}
                                component={data.component}
                            />
                        ))}
                    </Switch>
                </div>
            </div>
            <Footer />
        </div>
        
    </Router>
   ); 
};

export default Markup;
