import React,{Fragment} from 'react';
import {Link} from 'react-router-dom';

class Order extends React.Component{
	constructor(props){
        super(props)
        this.state = {
			item:[]
		}
	}
	componentDidMount(){
		const item = this.props.location.item;
		this.setState({item:item})
		console.log(this.props.location.item,"item");
	}

	render(){
		return(
			<Fragment>  
				<div className="form-head d-flex mb-3 mb-md-5 align-items-start">
					<div className="mr-auto d-none d-lg-block">
						<Link to={"ordenes"} className="text-primary d-flex align-items-center mb-3 font-w500" >
						<svg className="mr-3" width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0.274969 5.14888C0.27525 5.1486 0.275484 5.14827 0.275812 5.14799L5.17444 0.272997C5.54142 -0.0922061 6.135 -0.090847 6.5003 0.276184C6.86555 0.643168 6.86414 1.23675 6.49716 1.60199L3.20822 4.87499H23.0625C23.5803 4.87499 24 5.29471 24 5.81249C24 6.33027 23.5803 6.74999 23.0625 6.74999H3.20827L6.49711 10.023C6.86409 10.3882 6.8655 10.9818 6.50025 11.3488C6.13495 11.7159 5.54133 11.7171 5.17439 11.352L0.275764 6.47699C0.275483 6.47671 0.27525 6.47638 0.274921 6.4761C-0.0922505 6.10963 -0.0910778 5.51413 0.274969 5.14888Z" fill="#EA4989"/>
						</svg>
						Regresar</Link >
						<h3 className="text-black font-w600 mb-0 fs-22 mb-2">ID del Envío #{this.state.item.order_id}</h3>
					</div>				
				</div>
				<div className="row">
					<div className="col-xl-9 col-xxl-12">
						<div className="row">
							<div className="col-lg-12">
								<div className="card">
									<div className="widget-timeline-icon">
										<ul className="timeline">
											<li>
												<div className="icon bg-primary"></div>
												<Link to={"#"} className="timeline-panel text-muted" >
													<h4 className="mb-2 mt-0">Orden Creada</h4>
													<p className="fs-14 mb-0 ">{this.state.item.created_at}</p>
												</Link >
											</li>
											<li className="border-dark">
												<div className="icon bg-primary"></div>
												<Link to={"#"} className="timeline-panel text-muted" >
													<h4 className="mb-2 mt-0">Enviado</h4>
													<p className="fs-14 mb-0 "></p>
												</Link >
											</li>
											<li>
												<div className="icon bg-dark"></div>
												<Link to={"#"} className="timeline-panel text-muted" >
													<h4 className="mb-2 mt-0">Entregado</h4>
													<p className="fs-14 mb-0 ">Sat, 23 Jul 2020, 01:24 PM</p>
												</Link >
											</li>
										</ul>	
									</div>
								</div>
							</div>
							<div className="col-xl-12 col-md-12 col-lg-12">
								<div className="card">
									<div className="card-body rounded" style={{background:"#3f4953"}}>
										<div className="row mx-0 align-items-center">
											<div className="media align-items-center col-xl-6 col-lg-5 px-0 mb-3 mb-md-0">
												<img className="mr-3 img-fluid rounded-circle" width="100" src={require("../../images/profile/5.png").default} alt="DexignZone" />
												<div className="media-body">
													<p className="text-white mb-1 wspace-no">Delivery guy</p>
													<h3 className="mb-1 text-white  fs-22">Rainold Hawkins</h3>
													<small className="text-gray wspace-no">ID {this.state.item.driver_id}</small>
												</div>
											</div>
											<div className="text-left text-lg-right col-xl-6 col-lg-7 p-0 mt-lg-0 mt-3">
												<div className="iconbox mb-3 mr-3 mb-md-0">
													<i className="las la-phone"></i>
													<small>Telepon</small>
													<p>+12 345 5662 66</p>
												</div>
												<div className="iconbox mb-md-0">
													<i className="las la-shipping-fast"></i>
													<small>Delivery Time</small>
													<p>12:52 AM</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default Order;