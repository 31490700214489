import React, { Fragment, useState,useEffect } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import ChatBox from "../ChatBox";
import Api from "../../../modules/api"
import swal from "sweetalert";

const KokiNav = ({ title }) => {
   const [toggle, setToggle] = useState("");
   const [username, setUsername] = useState("");
   const [profile, setProfile] = useState("");
   const [profileVisible, isProfileVisible] = useState(false);
   const onClick = (name) => setToggle(toggle === name ? "" : name);
   const logout = () =>{
      Api.logout().then((res)=>{
         if(!res)
         {
            swal("Alerta","erro inesperado","error")
         }
      }).finally(()=>{
         localStorage.removeItem('userData');
         isProfileVisible(false)
      })
   }
   useEffect(() => {
   
      const userData = localStorage.getItem('userData')
      if(userData)
      {
         const user = JSON.parse(userData);
         isProfileVisible(true)
         setUsername(user.firstName+ " "+user.lastName)
         setProfile(user.profileImage)
      }
    },[]);
   return (
      <Fragment>
         <NavHader />
         <SideBar 
            profileVisible={profileVisible}
         />
         <Header
            onNote={() => onClick("chatbox")}
            onActivity={() => onClick("activity")}
            onNotification={() => onClick("notification")}
            onProfile={() => onClick("profile")}
            toggle={toggle}
            title={title}
            username={username}
            profile={profile}
            onLogout={() => logout()}
            profileVisible={profileVisible}
         />
         <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
      </Fragment>
   );
};

export default KokiNav;
