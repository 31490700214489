import React, { useState, useEffect } from "react";
import MetarialDate from "../../components/Forms/Pickers/MetarialDate";
import MetarialTime from "../../components/Forms/Pickers/MetarialTime";
import swal from "sweetalert";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

const FechaEnvio = (props) => {
    const style = {
        border: "none",
        fontSize: "1rem"
     };
    useEffect(() => {
        swal({
            text: "Horario para recepción de PIK: lunes-sábado de 8am a 5pm. PIKs recibidos fuera de este horario serán atendidas el día hábil siguiente",
            icon: "info",
            button: {
               text: "ok",
            },
         });
     },[])
   return (
      <section className="text-center mb-3">
        <div className="text-center mb-3">
            <h5 className="text-center mb-4 text-primary">¿Cuando envías?</h5>
        </div>
        <div className="row  justify-content-between">
            <div className="form-group col-xl-2"/>
            <div className="form-group col-xl-4">
                <span>Fecha</span>
                <div className="form-control ">
                    <MetarialDate name="date" selectedDate={props.selectedDate} 
                    handleDateChange={props.handleDateChange}/>
                </div>
            </div>
            {/* <div className="form-group col-xl-4">
                <span>Hora</span>
                <div className="form-control ">
                    <MetarialTime selectedDate={props.selectedTime}  name="time"
                    handleDateChange={props.handleTimeChange}/>
                </div>
            </div> */}
            <div className="form-group col-xl-4">
                <span>Hora</span>
                <div className="form-control ">
                    <TimePicker 
                        showSecond={false}
                        format="h:mm a"
                        use12Hours
                        defaultValue={props.selectedTime}
                        value={props.selectedTime}
                        onChange={props.handleTimeChange}
                        placeholder="hora"
                        allowEmpty={false}
                    />
                </div>
            </div>
            <div className="form-group col-xl-2"/>
        </div>
      </section>
   );
};

export default FechaEnvio;

