import React from "react";
import {  Col } from "react-bootstrap"

const Entrega = (props) => {
   
   return (
      <section className="text-center mb-3">
        <div className="text-center mb-3">
            <h5 className="text-center mb-4 text-primary">¿A quien entregamos?</h5>
        </div>
        <div className="row justify-content-between">
            <div className="form-group col-xl-6">
               <input
                  type="text"
                  name="name" 
                  placeholder="Nombre"
                  value={props.dropOffData.name}
                  className="form-control text-primary"
                  onChange={props.handleBlurStep2}
               />
            </div>
            <div className="form-group col-xl-6">
               <input
                  type="text"
                  name="lastName" 
                  placeholder="Apellido"
                  value={props.dropOffData.lastName}
                  className="form-control text-primary"
                  onChange={props.handleBlurStep2}
               />
            </div>
         </div>
         <div className="row  justify-content-between">
            <Col xl={3} lg={3} md={3} sm={3} xs={3} className="form-group">
             <input
                  type="number"
                  name="code" 
                  placeholder="Código"
                  value={props.dropOffData.code}
                  className="form-control text-primary"
                  onChange={props.handleBlurStep2}
               />
            </Col>
            <Col xl={9} lg={9} md={9} sm={9} xs={9} className="form-group">
               <input
                  type="number"
                  name="number" 
                  placeholder="Número de teléfono móvil"
                  value={props.dropOffData.number}
                  className="form-control text-primary"
                  onChange={props.handleBlurStep2}
               />
            </Col>
         </div>
      </section>
   );
};

export default Entrega;

