import Api from '../services/api'

export default {
    //create new season
    async login (data) {
      let authData, result;
      try {
              authData = await Api.login(data)
              result = authData.data;
              if(result.result === "success" && result.data.user_type !== "1")
              {
                  return false;
              }

      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    
    async forgot (data) {
      let authData, result;
    try {
            authData = await Api.forgot(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async signup (data) {
      let authData, result;
      try {
              authData = await Api.signup(data)
              result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async createOrder (data) {
      let authData, result;
      try {
            authData = await Api.createOrder(data)
            console.log(authData)
            result = authData.data;
      } catch (error) {
        console.log({error})
        result = false;
      }
      return result;
    },
    async logout () {
      let  authData,result;
      try {
         const userData = localStorage.getItem('userData')
         if(userData)
         {
            const user = JSON.parse(userData);
            if(user.userId)
            {
              let FormData = require('form-data');
              let data = new FormData();
              data.userId = userData.userId;
              authData = await Api.logout(data)
              return true;
            }
         }
         return false;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
        
    async customerOrderHistory (data) {
      let authData, result;
    try {
            authData = await Api.customerOrderHistory(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async getUserDeliveries (data) {
      let authData, result;
      try {
            authData = await Api.getUserDeliveries(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async editProfile (data) {
      let authData, result;
      try {
              authData = await Api.editProfile(data)
              result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async settings () {
      let authData, result;
      try {
            authData = await Api.settings()
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async getVehicleType (data) {
      let authData, result;
      try {
            authData = await Api.getVehicleType(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async sendContact (data) {
      let authData, result;
    try {
            authData = await Api.sendContact(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async checkForgetTime (data) {
      let authData, result;
    try {
            authData = await Api.checkForgetTime(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async updatePassword (data) {
      let authData, result;
       try {
            authData = await Api.updatePassword(data)
            result = authData.data;
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result;
    },
    async getDeliveryCancelReason(){
      let authData, result;
      const userData = localStorage.getItem('userData')
      if(userData){
        try {
          const user = JSON.parse(userData); 
          authData = await Api.getDeliveryCancelReason({userid : user.userId})
          result = authData.data;
        } catch (error) {
          result = false;
        }
      }
      return result;
    },
    async cancelDelivery(data){
      let authData, result;
      try {
        authData = await Api.cancelDelivery(data)
        result = authData.data;
      } catch (error) {
        result = false;
      }
      return result;
    },
    async listRestrictedAreas(){
      let authData, result;
      try {
        const userData = localStorage.getItem('userData')
        if(userData){
          const user = JSON.parse(userData); 
          authData = await Api.listrestrictedarea({userid : user.userId})
          result = authData.data;
        }
      } catch (error) {
        result = false;
      }
      return result;
    },
    async checkPixelPayment(data){
      let authData, result;
      try {
        authData = await Api.checkPixelPayment(data)
        result = authData.data;
      } catch (error) {
        result = false;
      }
      return result;
    }
}