import React,{ useState, useEffect  } from "react";
import moment from 'moment';
import getDistance from 'geolib/es/getDistance';
import { LoadScript, useJsApiLoader } from '@react-google-maps/api';
import Api from "../../modules/api"
import logo from "../../images/logo.png";
import { apiKey} from "../../services/maps"

const ConfirmPik = ({deliveryData,dropOffData,selectedDate,selectedTime,setExtra, dropoffLocation,pickupLocation,userId}) => {
   const [distanceInkms, setDistanceInKms] = useState(0)
   const [totalDeliveryCost, setTotalDeliveryCost] = useState(0)
   const [settings, setSettings] = useState({})
   const { isLoaded, loadError } = useJsApiLoader({
      googleMapsApiKey: apiKey
    })
   
   useEffect(() => {
      async function fetchData(){
         let response = await Api.settings();
         let vehicle = {};
         if(response){
            if(response.result === "success")
            {
               vehicle = (response.vehicle)
            }
         }
         let FormData = require('form-data');
         let data1 = new FormData();
         data1.append('user_id', userId);   
         let data = await Api.getVehicleType(data1);
         let vehicleData = [];
         if(data){
            vehicleData = data.data;
         }
         console.log("fetchSettings")
   
         // Get latitude & longitude from address.

         console.log("calculate")
         console.log({ latitude: pickupLocation.latitude, longitude: pickupLocation.longitude })
         console.log({ latitude: dropoffLocation.latitude, longitude: dropoffLocation.longitude  })
         let distance = 0;
         try {
            const pickup_lat_long = `"${pickupLocation.latitude},${pickupLocation.longitude}"`;
            const dropoff_lat_long = `"${dropoffLocation.latitude},${dropoffLocation.longitude}"`;
            const request  =  {
               travelMode : 'DRIVING',
               drivingOptions: {
                  departureTime: new Date(/* now, or future date */),
                  trafficModel: 'optimistic'
                },
               region: 'hn',
               origin: pickup_lat_long,
               destination: dropoff_lat_long
             };

             const result = await new Promise((resolve, reject) => {
               const directionsService = new window.google.maps.DirectionsService()
               directionsService.route(request, (result, status) => {
                 if (status == 'OK') {
                     resolve(result);
                 }else{
                     reject(null);
                  }
               });
             });
             
             distance =   result.routes[0].legs[0].distance.value/1000;
         } catch (err) {
            console.log(err,"no hay rutas disponibles")
            distance = getDistance(
               { latitude: pickupLocation.latitude, longitude: pickupLocation.longitude },
               { latitude: dropoffLocation.latitude, longitude: dropoffLocation.longitude  }
            )/1000;
         }

         //console.log({distance});
         let deliveryCost;
         let index = vehicleData.findIndex((item) => item.name === dropOffData.type);
         if(index>=0)
         {
            //set 4 ranges
            if(distance<=vehicleData[index].round_1)
            {
               deliveryCost = parseFloat(vehicleData[index].price_1,2);
            }else{
               if(distance<=vehicleData[index].round_2)
               {
                  deliveryCost =  parseFloat(vehicleData[index].price_2,2);
               }else{
                  if(distance<=vehicleData[index].round_3)
                  {
                     deliveryCost =  parseFloat(vehicleData[index].price_3,2);
                  }else{
                     if(distance<=vehicleData[index].round_4)
                     {
                        deliveryCost =  parseFloat(vehicleData[index].price_4,2);
                     }else{
                        alert("Ha excedido el limite de distancia.")
                        deliveryCost = 0;
                     }
                  }
               }
            }
         }
        // deliveryCost = Math.round(deliveryCost)
        // setTotalDeliveryCost(deliveryCost === "NaN" ? 0:deliveryCost)
         const driverDeliveryCost = (deliveryCost - ((deliveryCost * parseFloat(vehicle.admin_percentage,2) / 100)));
         return {
            distanceInkms : distance,
            totalDeliveryCost : deliveryCost,
            driverDeliveryCost : driverDeliveryCost,
            pickupLocation,
            dropoffLocation,
         };
      }
      if(isLoaded)
      {
         fetchData().then((d)=>{
            setDistanceInKms(d.distanceInkms)
            setTotalDeliveryCost(d.totalDeliveryCost)
            setExtra({
               distanceInkms: d.distanceInkms,
               totalDeliveryCost: d.totalDeliveryCost,
               driverDeliveryCost : d.driverDeliveryCost,
               pickupLocation,
               dropoffLocation
            })
         })    
      }
    }, [isLoaded]);



   return (
      <section>
         <div className="row align-items-center ml-1 mb-3">
            <img style={{borderRadius:10, height:40, widows:40}} className="mr-2" src={logo} alt="" />
            <h5 className="text-left">Origen:</h5>
         </div>
         <div className="form-group">
            <input
               type="text"
               name="address" 
               value={deliveryData.address}
               className="form-control text-primary"
            />
         </div>
         <div className="row align-items-center ml-1 mb-3">
            <img style={{borderRadius:10, height:40, widows:40}} className="mr-2" src={logo} alt="" />
            <h5 className="text-left">Destino:</h5>
         </div>
         <div className="form-group">
            <input
               type="text"
               name="addressDropoff" 
               value={dropOffData.address}
               className="form-control text-primary"
            />
         </div>
         <div className="text-center">
            <h5 className="text-left">Tipo de vehículo:</h5>
         </div>
         <div className="form-group">
            <input
               type="text"
               name="type" 
               value={dropOffData.type}
               className="form-control text-primary"
            />
         </div>
         {/* <div className="row align-items-center">
            <div className="text-center form-group col-xl-5">
               <h5 className="text-right">Distancia de entrega:</h5>
            </div>
            <div className="form-group col-xl-7">
               <input
                  type="text"
                  name="type" 
                  value={distanceInkms+ "KM"}
                  className="form-control text-primary"
               />
            </div>
         </div> */}
         <div className="row align-items-center">
            <div className="text-center form-group col-xl-5">
               <h5 className="text-left">Fecha de entrega:</h5>
            </div>
            <div className="form-group col-xl-7">
               <input
                  type="text"
                  name="type" 
                  value={moment(selectedDate).format("DD/MM/YYYY")}
                  className="form-control text-primary"
               />
            </div>
         </div>
         {/* <div className="row align-items-center">
            <div className="text-center form-group col-xl-5">
               <h5 className="text-right">Hora de entrega:</h5>
            </div>
            <div className="form-group col-xl-7">
               <input
                  type="text"
                  name="type" 
                  value={moment(selectedTime).format("hh:MM A")}
                  className="form-control text-primary"
               />
            </div>
         </div> */}
         <div className="row align-items-center">
            <div className="text-center form-group col-xl-5">
               <h5 className="text-left">Precio:</h5>
            </div>
            <div className="form-group col-xl-7">
               <input
                  type="text"
                  name="type" 
                  value={parseFloat(totalDeliveryCost).toFixed(2)+" Lps."}
                  className="form-control text-primary"
               />
            </div>
         </div>

      </section>
   );
};

export default ConfirmPik;
