import React  from "react";
import { Link } from "react-router-dom";

/// Scroll
// import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
//import profile from "../../../images/profile/12.png";
// import avatar from "../../../images/avatar/1.jpg";

const Header = ({ onNote, toggle, onProfile, onActivity, onNotification, onLogout,username,profile,profileVisible}) => {
   var path = window.location.pathname.split("/");
   var name = path[path.length - 1].split("-");
   var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
   var copname = path[path.length - 1];
	var page_name 
	if(copname === "login")
      page_name = "Iniciar sesión";
   if(copname === "signup")
      page_name = "Regístrate";
   if(copname === "forgot")
      page_name = "Restablecer contraseña";
   if(copname === "")
      page_name = "Crear nueva solicitud";
   if(copname === "ordenes")
      page_name = "Mis Pedidos";
   if(copname === "detalle")
      page_name = "Detalle del envio";
   if(copname === "perfil")
      page_name = "Perfil";
   if(copname === "success")
      page_name = "Gracias"
   if(copname === "contact")
      page_name = "Contáctanos"
   
   return (
      <div className="header">
         <div className="header-content">
            <nav className="navbar navbar-expand">
               <div className="collapse navbar-collapse justify-content-between">
                  <div className="header-left">
                     <div
                        className="dashboard_bar text-primary"
                        style={{ textTransform: "capitalize" }}
                     >
                        {page_name}
                     </div>
                  </div>
                  { profileVisible &&
                  <ul className="navbar-nav header-right">
                     <li
                        className={`nav-item dropdown header-profile ${
                           toggle === "profile" ? "show" : ""
                        }`}
                        onClick={() => onProfile()}
                     >
                        <Link to={"#"}
                           className="nav-link"
                           role="button"
                           data-toggle="dropdown"
                        >
                           <div className="header-info">
								      <span className="text-primary">{username}</span>
                           </div>
                           <img src={profile} width="20" alt="" />
                        </Link>
                        <div
                           className={`dropdown-menu dropdown-menu-right ${
                              toggle === "profile" ? "show" : ""
                           }`}
                        >
                           <Link
                              to="/perfil"
                              className="dropdown-item ai-icon"
                           >
                              <i className="fa fa-user fa-lg text-primary"></i>

                              <span className="ml-2">Perfil </span>
                           </Link>
                           {/* <Link
                              to="/login"
                              className="dropdown-item ai-icon"
                           >
                              <i className="fa fa-sign-in fa-lg text-secondary"></i>

                              <span className="ml-2">Iniciar sesión</span>
                           </Link> */}
                           <Link
                              to={"login"}
                              onClick={() => onLogout()} 
                              className="dropdown-item ai-icon"
                           >
                              <i className="fa fa-sign-out fa-lg text-danger"></i>
                              
                              <span className="ml-2">Cerrar sesión </span>
                           </Link>
                        </div>
                     </li>
					 
                  </ul>
                  }
               </div>
            </nav>
         </div>
      </div>
   );
};

export default Header;
