import React, { useState } from "react";
import logo from "../../../images/logo.png";
import MapPicker  from "./MapPicker";
import Select from 'react-select'
import { colonias } from "../../../constants"

const PikOrigen = (props) => {
   function handleChangeLocation (lat, lng){
     props.setPickupLocation({latitude:lat, longitude:lng});
   }

   const setDeliveryData = (colonia) =>
   {
      // console.log("buscar:"+colonia);
      try{
         const index = colonias.findIndex((item)=> {
            // console.log(item.value)
            return item.value.trim().toLowerCase() === colonia.trim().toLowerCase()
         });
         console.log({index})
         if(index>=0){
            const coloniaSelects = colonias[index];
            const newDeliveryData = { ...props.deliveryData };
            newDeliveryData.address = coloniaSelects.label;
            newDeliveryData.colonia = coloniaSelects;
            props.setDeliveryData(newDeliveryData);
         }
      }catch(err){
         console.log(err)
      }

   }

   const handleInputChange = (coloniaSelects) => {
      const newDeliveryData = { ...props.deliveryData };
      if(coloniaSelects){
         newDeliveryData.address = coloniaSelects.label;
         newDeliveryData.colonia = coloniaSelects;
      }else{
         newDeliveryData.colonia=null;
      }
      props.setDeliveryData(newDeliveryData);
   };

   return (
      <section>
         <div className="row justify-content-center align-items-center mb-3">
            <img style={{borderRadius:10}} className=" mr-2" src={logo} alt="" />            
            <h5 className="text-center text-primary">Origen</h5>
         </div>
        <div className="form-group">
            <Select 
               value={props.deliveryData.colonia}
               options={colonias} 
               onChange={handleInputChange}
               name="colonias"
               isClearable
               isSearchable
               placeholder="Seleccione una Colonia"
               autoFocus
               theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                     ...theme.colors,
                     primary: '#2E2EF1',
                   },
                })}
            />
        </div>
        <div className="form-group">
            <input
               type="textarea"
               name="detail" 
               placeholder="Dirección de origen"
               value={props.deliveryData.detail}
               className="form-control text-primary"
               onChange={props.handleBlur}
            />
         </div>
         <MapPicker 
            address={props.deliveryData.colonia} //enviar colonia no direccion
            updateCoords={handleChangeLocation} 
            setAddress={setDeliveryData}
            setValidArea={props.setValidArea}
         />
         <br/>
      </section>
   );
};

export default PikOrigen;

