import React, {useState, useEffect } from "react";
import swal from "sweetalert";
import Api from "../../modules/api"

const Profile = () => {
   const [editProfile, seteditProfile] = useState(
      {
         id:"",
         company:"",
         name:"",
         lastName:"",
         email:"", 
         code:"",
         number:"",
         phoneNumber:"",
         password:"",
         confirPassword:"",
         numberHome:"",
         stName:"",
         city:"",
         state:"",
         country:"",
         imageProfile:""
      }
   );

   const [editData, setEditData] = useState(true);
   useEffect(() => {
   
      const userData = localStorage.getItem('userData')
      if(userData){
         const user = JSON.parse(userData);
         const editData = {
            id:user.userId,
            company:user.companyName,
            name:user.firstName,
            lastName:user.lastName,
            email:user.email, 
            code:user.countryCode,
            number:user.phone,
            numberHome:user.houseNo,
            stName:user.streetName,
            city:user.suburb,
            state:user.state,
            country:user.countryName,
            imageProfile:user.profileImage
         }
         seteditProfile(editData) 
      }
    
  },[]);
   const handleBlur = (e) => {
      const neweditProfile = { ...editProfile };
      neweditProfile[e.target.name] = e.target.value;
      seteditProfile(neweditProfile);
   };

   const submitHandler = (e) => {
      e.preventDefault();
      let FormData = require('form-data');
      let data = new FormData();
      data.append('userid',editProfile.id);
      data.append('firstname', editProfile.name);
      data.append('lastname', editProfile.lastName);
      data.append('email', editProfile.email);
      data.append('phone', editProfile.number);
      data.append('user_type', 1);
      data.append('house_no', editProfile.numberHome);
      data.append('street_name', editProfile.stName);
      data.append('country_name', editProfile.country);
      data.append('country_code', editProfile.code);
      data.append('state', editProfile.state);
      data.append('suburb', editProfile.city);
      data.append('company_name', editProfile.company);
      
      if(editProfile.name === "" || editProfile.lastName === "" || editProfile.email === "" || editProfile.code === "" || editProfile.number === "" ||  editProfile.numberHome ==="" || editProfile.stName ==="" || editProfile.city ==="" || editProfile.state ==="" ||editProfile.country ===""){
         console.log("Faltan datos")
         swal("Alerta", "Todos los campos son necesarios", "error");
      }else{
         Api.editProfile(data)
         .then((response)=> {
            console.log(JSON.stringify(response));
            if(response)
            {
               if(response.result === "failure"){
                  swal("Alerta",response.message, "error");
               }else{
                  // setSession(response.data)
                  // console.log(JSON.stringify(editProfile));
                  setSession(response.data)
                  swal({
                     title: "Alerta", 
                     text: response.message, 
                     icon: "success",
                     button: true,
                  })
                  .then((willDelete) => {
                     window.location.reload();
                  })

                  setEditData(true);
               }
            }else{
               swal("Alerta","erro inesperado")
            }
         })
         .catch( (error)=> {
            swal("Alerta",error, "error");
            console.log(error);
         });
         console.log(JSON.stringify(data));
      }
   };

   const setSession = (authResult) => {
      // Set the time that the access token will expire at
      //5minutos
      let expiresAt = JSON.stringify((600000) + new Date().getTime());
      //console.log({authResult})
      const userData = {
         companyName : authResult.company_name,
         countryCode : authResult.country_code,
         countryName : authResult.country_name,
         email : authResult.email,
         firstName: authResult.firstname,
         houseNo : authResult.house_no,
         lastName: authResult.lastname,
         phone: authResult.phone,
         streetName: authResult.street_name,
         state: authResult.state,
         suburb: authResult.suburb,
         postCode: authResult.postcode,
         userId: authResult.user_id,
         userType: authResult.user_type,
         profileImage: authResult.profile_image
      }
      localStorage.setItem('userData',  JSON.stringify(userData));
      localStorage.setItem('expires_at', expiresAt);
   };

   const editHandler = () => {
      setEditData(false)
   };

   return (
      <div className="row justify-content-around h-80">
         <div className="authincation-content">
            <div className="col-xl-12 auth-form">
               <div className="row justify-content-between align-items-center mb-3">
                  <h4 className="text-center mb-0 text-primary">Información personal</h4>
                  <button  type="submit"  className="btn btn-primary"  onClick={() => editHandler()} > Editar </button>
               </div>
               <form  action=""  onSubmit={(e) => submitHandler(e)}  >
                  <div className="form-group">
                     <input
                        type="text"
                        name="company" 
                        placeholder="Nombre de la Empresa"
                        value={editProfile.company}
                        className="form-control text-primary"
                        onChange={handleBlur}
                        disabled={editData}
                     />
                  </div>
                  <div className="row  justify-content-between">
                     <div className="form-group col-xl-6">
                        <input
                           type="text"
                           name="name" 
                           placeholder="Nombre"
                           value={editProfile.name}
                           className="form-control text-primary"
                           onChange={handleBlur}
                           disabled={editData}
                        />
                     </div>
                     <div className="form-group col-xl-6">
                        <input
                           type="text"
                           name="lastName" 
                           placeholder="Apellido"
                           value={editProfile.lastName}
                           className="form-control text-primary"
                           onChange={handleBlur}
                           disabled={editData}
                        />
                     </div>
                  </div>
                  <div className="form-group">
                     <input  
                        type="email" 
                        name="email" 
                        placeholder="Correo electrónico"
                        value={editProfile.email} 
                        className="form-control text-primary"   
                        onChange={handleBlur}   
                        disabled={editData}
                     />
                  </div>
                  <div className="row  justify-content-between">
                     <div className="form-group col-xl-3">
                        <input
                           type="number"
                           name="code" 
                           placeholder="Código"
                           value={editProfile.code}
                           className="form-control text-primary"
                           onChange={handleBlur}
                           disabled={editData}
                        />
                     </div>
                     <div className="form-group col-xl-9">
                        <input
                           type="number"
                           name="number" 
                           placeholder="Número de teléfono móvil"
                           value={editProfile.number}
                           className="form-control text-primary"
                           onChange={handleBlur}
                           disabled={editData}
                        />
                     </div>
                  </div>
                  <div className="mb-3">
                     <h4 className="text-center mb-4 text-primary">Dirección</h4>
                  </div>
                  <div className="row  justify-content-between">
                     <div className="form-group col-xl-6">
                        <input
                           type="text"
                           name="numberHome" 
                           placeholder="Número de casa"
                           value={editProfile.numberHome}
                           className="form-control text-primary "
                           onChange={handleBlur}
                           disabled={editData}
                        />
                     </div>
                     <div className="form-group col-xl-6">
                        <input  
                           type="text" 
                           name="stName" 
                           placeholder="Nombre de la calle"
                           value={editProfile.stName} 
                           className="form-control text-primary"   
                           onChange={handleBlur}   
                           disabled={editData}
                        />
                     </div>
                  </div>
                  <div className="row justify-content-between">
                     <div className="form-group col-xl-6">
                        <input 
                           type="text" 
                           name="city" 
                           placeholder="Ciudad"
                           value={editProfile.city}  
                           className="form-control text-primary" 
                           onChange={handleBlur}  
                           disabled={editData}
                        />
                     </div>
                     <div className="form-group col-xl-6">
                        <input 
                           type="text" 
                           name="state" 
                           placeholder="Estado"
                           value={editProfile.state} 
                           className="form-control text-primary"  
                           onChange={handleBlur}  
                           disabled={editData}
                        />
                     </div>
                  </div>
                  <div className="form-group">
                     <input 
                        type="text" 
                        name="country" 
                        placeholder="País"
                        value={editProfile.country} 
                        className="form-control text-primary"  
                        onChange={handleBlur}  
                        disabled={editData}
                     />
                  </div>
                  <div className="text-center">
                     <button  type="submit"  className="btn btn-primary btn-block"  disabled={editData} onClick={() => submitHandler} > Guardar </button>
                  </div>
               </form>
            </div>
         </div>
      </div>  
   );
};

export default Profile;
