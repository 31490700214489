import React, { Fragment,useState,useEffect } from "react";
import StepWizard from 'react-step-wizard'; 
import ConfirmPik from "./ConfirmPik";
import Login from "./StepLogin"
import Nav from './Nav';
import swal from "sweetalert";
import Api from "../../modules/api";
import PixelConfig from "../../services/pixelConfig"
import moment from "moment";
import QuienEnvia from "./steps/QuienEnvia";
import PikOrigen from "./steps/PikOrigen";
import QueEnvias from "./steps/QueEnvias";
import Vehiculo from "./steps/Vehiculo";
import FechaEnvio from "./steps/FechaEnvio";
import Entrega from "./steps/Entrega";
import Destino from "./steps/PikDestino";
import logo from "../../images/logo.png";
import { Modal , Spinner } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import history from "../../history";
import PixelPay from "../../modules/pixelpay"
import Pagadito from "../../modules/pagadito"
import PixelPayLogo from "../../images/pixel_pay_logo.png";
import UsPayLogo from "../../images/uspay-logo-v3.png";
// import PagaditoLogo from "../../images/pg-logo-pagadito.jpg";
import CashLogo from "../../images/cash_logo.png"
// import Geocode from "react-geocode";
import USPay from "../../modules/uspay"

const Delivery = () => {
   const [stepper,setStepper] = useState(null)
   const [userId , setUserId] = useState(null)
   const [isValidArea, setValidArea] = useState(true)
   const [isLoading, setIsLoading] = useState(false)
   const [paymentClicked, setPaymentClicked] = useState(0)

   const prevStyle = {
      background: "#F7FAFC",
      borderWidth: "0px",
      color: "#2e2ef1",
      borderRadius: "4px",
      fontSize: "14px",
      fontWeight: "600",
      padding: "0.55em 2em",
      border: "1px solid #2e2ef1",
      marginRight: "1rem",
   };

   const nextStyle = {
      background: "#2e2ef1",
      borderWidth: "0px",
      color: "#fff",
      borderRadius: "4px",
      fontSize: "14px",
      fontWeight: "600",
      padding: "0.55em 2em",
   };

   const finishStyle = {
      background: "#2E2EF1",
      borderWidth: "0px",
      color: "#fff",
      borderRadius: "4px",
      fontSize: "14px",
      fontWeight: "600",
      padding: "0.55em 2em",
   };
   
   const disableStyle = {
      background: "#9e9e9e",
      borderWidth: "0px",
      color: "#fff",
      borderRadius: "4px",
      fontSize: "14px",
      fontWeight: "600",
      padding: "0.55em 2em",
   }

   const [pickupLocation, setPickupLocation] = useState({latitude:0,longitude:0})
   const [dropoffLocation, setDropoffLocation] = useState({latitude:0,longitude:0})

   const [selectedDate, handleDateChange] = useState(new Date());
   const [selectedTime, setSelectedTime] = useState(moment());
   const [deliveryData, setDeliveryData] = useState(
      {
         company:"",
         name:"",
         lastName:"",
         code:"504",
         number:"",
         phoneNumber:"",
         address:"",
         detailItem:"",
         amount:"", 
         detail:"",
         id_restricted_area:"",
         colonia: null
      }
   );
   const [dropOffData, setDropOffData] = useState(
      {
         company:"",
         name:"",
         lastName:"",
         code:"504",
         number:"",
         address:"",
         type:"" ,
         detail: "",
         id_restricted_area:"",
         colonia: null
      }
   );

   const [signUpData, setSignUpData] = useState(
      {
         company:"",
         name:"",
         lastName:"",
         email:"", 
         code:"504",
         number:"",
         phoneNumber:"",
         password:"",
         confirPassword:"",
         numberHome:"",
         stName:"",
         city:"",
         state:"",
         country:"",
         imageProfile:""
      }
   );

   const [extra, setExtra] = useState({})

   const handleBlur = (e) => {
      const newDeliveryData = { ...deliveryData };
      newDeliveryData[e.target.name] = e.target.value;
      setDeliveryData(newDeliveryData);
      if(userId === null){
         let newSignupData = { ...signUpData};
         newSignupData.company = newDeliveryData.company;
         newSignupData.name = newDeliveryData.name;
         newSignupData.lastName = newDeliveryData.lastName;
         newSignupData.code = newDeliveryData.code;
         newSignupData.number = newDeliveryData.number;
         newSignupData.phoneNumber = newDeliveryData.number;
         if(pickupLocation.latitude !== 0 && pickupLocation.longitude !== 0)
         {
            //obtener ciudad y pais
            newSignupData.stName = newDeliveryData.address;
            newSignupData.city = "Tegucigalpa";
            newSignupData.state = "Distrito Central";
            newSignupData.country = "Honduras";
            setSignUpData(newSignupData)
            // Geocode.fromLatLng(pickupLocation.latitude, pickupLocation.longitude).then(
            //    (response) => {
            //      const formatted_address = response.results[0].formatted_address;
            //      let city = "", state = "", country = "";
            //      for (let i = 0; i < response.results[0].address_components.length; i++) {
            //        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
            //          switch (response.results[0].address_components[i].types[j]) {
            //            case "locality":
            //              city = response.results[0].address_components[i].long_name;
            //              break;
            //            case "administrative_area_level_1":
            //              state = response.results[0].address_components[i].long_name;
            //              break;
            //            case "country":
            //              country = response.results[0].address_components[i].long_name;
            //              break;
            //          }
            //        }
            //      }
            //      newSignupData.stName = formatted_address;
            //      newSignupData.city = city;
            //      newSignupData.state = state;
            //      newSignupData.country = country;
            //      setSignUpData(newSignupData)
            //    },
            //    (error) => {
            //      console.error(error);
            //      setSignUpData(newSignupData)
            //    }
            //  )
         }else{
            setSignUpData(newSignupData)
         }
         
      }

   };

   const handleBlurStep2 = (e) => {
      const newDropOffData = { ...dropOffData };
      newDropOffData[e.target.name] = e.target.value;
      setDropOffData(newDropOffData);
   };

   const handleTimeChange = (value) =>{
      //const newdate = e.target.value;
      setSelectedTime(value)
      //alert(e.target.value)
   }

   const [orderID, setOrderId] = useState("")
   const storageListener = (e) =>{
      // console.log(e.key)
      // console.log(orderID)
      if(orderID !== "")
      {
         switch(e.key){
            case "paymentReceived":
               const hash = window.calcMD5(`${orderID}|${PixelConfig.PIXEL_KEY}|${PixelConfig.PIXEL_SECRET}`)
               // console.log(hash)
               if(hash === e.newValue)
               {
                  //pago aceptado guardar
                  //save();
                 // setOrderId("");
               }
               break;
            case "pagaditoToken":
               if(orderID === e.newValue)
               {
                  //pago aceptado guardar
                  save();
                  setOrderId("");
               }
               break;
            default:
               break;
         }
      }
   }

   useEffect(() => {
      function load(){
         window.addEventListener("storage",storageListener);
      }
      load()
      return () => {
         window.removeEventListener("storage",storageListener);
       };
   },[orderID])

   const [userData, setUserData] = useState({});

   useEffect(() => {
      //console.log(window.calcMD5("00000001|s4ndb0x|1234567890"))
      function load(){
         //Geocode.setApiKey(apiKey);
         //Geocode.setLanguage("es");
         //Geocode.setLocationType("ROOFTOP");
          const userData = localStorage.getItem('userData');
          if(userData)
          {
             const user = JSON.parse(userData); 
             setUserId(user.userId);
             setUserData(user)
             setDeliveryData(
                {
                   company:user.companyName,
                   name:user.firstName,
                   lastName:user.lastName,
                   code:user.countryCode,
                   number:user.phone,
                   phoneNumber:user.phone,
                   address:user.streetName+" "+user.houseNo+" "+user.suburb+" "+user.state+" "+user.countryName,
                   detailItem:"",
                   amount:"", 
                   detail:"",
                   colonia:[]
                }
             )
          }
      }
      load();
   },[])

   
   const nextStep = () =>{
      const currstep = stepper.currentStep;
      switch(currstep)
      {
         case 1:
            if( deliveryData.name === "" || deliveryData.lastName === "" || deliveryData.code === "" || deliveryData.number === "" ){
               // console.log("Faltan datos")
               swal("Alerta", "Todos los campos son necesarios", "error");
            }else{
               stepper.nextStep();
            }
            break;
         case 2:
            if( deliveryData.colonia === null || deliveryData.detail === "")
            {
               console.log(deliveryData.address)
               swal("Alerta", "Todos los campos son necesarios", "error");
            }else{
               if(isValidArea)
                  stepper.nextStep();
               else  swal("Alerta", "Debe seleccionar un area válida", "error");
            }
            break;
         case 3:
            if(deliveryData.detailItem ==="" || deliveryData.amount ==="")
            {
               // console.log("Faltan datos")
               swal("Alerta", "Todos los campos son necesarios", "error");
            }else{
               stepper.nextStep();
            }
            break;
         case 4:
            if(dropOffData.type ==="")
            {
               // console.log("Faltan datos")
               swal("Alerta", "Seleccione un tipo de vehículo para su pedido", "error");
            }else
            {
               stepper.nextStep();
            }
            break;
         case 5:
            stepper.nextStep();   
            break;
         case 6:
            if(dropOffData.name === "" || dropOffData.lastName === "" || dropOffData.code === "" || dropOffData.number === "" )
            {
               // console.log("Faltan datos")
               swal("Alerta", "Todos los campos son necesarios", "error");
            }else
            {
               stepper.nextStep()   
            }
            break;
         case 7:
            if(dropOffData.address === "")
            {
               swal("Alerta", "Todos los campos son necesarios", "error");
            }else{
               if(isValidArea)
                  stepper.nextStep();
               else  swal("Alerta", "Debe seleccionar un area válida", "error");
            }
            break;
        /* case 2:
            //|| deliveryData.detailItem ==="" || deliveryData.amount ===""
            if(dropOffData.type === "" || dropOffData.name === "" || dropOffData.lastName === "" || dropOffData.code === "" || dropOffData.number === "" || dropOffData.address === ""){
               console.log("Faltan datos")
               swal("Alerta", "Todos los campos son necesarios", "error");
            }else{
               stepper.nextStep();
            }
            break;*/
         case 8:
            if(signUpData.company === "" || signUpData.name === "" || signUpData.lastName === "" || signUpData.email === "" || signUpData.code === "" || signUpData.number === "" || signUpData.password ==="" || signUpData.confirPassword ==="" || signUpData.numberHome ==="" || signUpData.stName ==="" || signUpData.city ==="" || signUpData.state ==="" ||signUpData.country ===""){
               // console.log("Faltan datos")
               swal("Alerta", "Todos los campos son necesarios", "error");
            }else{
               onSignUp(signUpData);
            }
            break;
         default:
            break;
      }

   }

   const save = () =>{
      setIsLoading (true);
      let FormData = require('form-data');
      let data = new FormData();
      // console.log(userId)
      data.append('user_id', userId);
      data.append('pickup_comapny_name', deliveryData.company);
      data.append('pickup_first_name', deliveryData.name);
      data.append('pickup_last_name', deliveryData.lastName);
      data.append('pickup_mob_number', deliveryData.number);
      data.append('pickupaddress', deliveryData.address);
      data.append('item_description', deliveryData.detailItem);
      data.append('item_quantity', deliveryData.amount);
      data.append('delivery_date', moment(selectedDate).format("DD/MM/YYYY"));
      data.append('pickup_special_inst', deliveryData.detail);
      data.append('dropoff_first_name', dropOffData.name);
      data.append('dropoff_last_name',  dropOffData.lastName);
      data.append('dropoff_mob_number',  dropOffData.number);
      data.append('dropoff_special_inst',  dropOffData.detail);
      data.append('dropoffaddress',  dropOffData.address);
      data.append('parcel_height', "");
      data.append('parcel_width', "");
      data.append('parcel_lenght', "");
      data.append('parcel_weight', "");
      data.append('delivery_type', "2HOUR");
      data.append('driver_delivery_cost', extra.driverDeliveryCost);
      data.append('delivery_distance', extra.distanceInkms);
      data.append('dropoff_comapny_name', dropOffData.company);
      data.append('vehicle_type', dropOffData.type);
      data.append('delivery_cost', extra.totalDeliveryCost);
      data.append('pickUpLat', extra.pickupLocation.latitude);
      data.append('pickUpLong', extra.pickupLocation.longitude);
      data.append('dropOffLat', extra.dropoffLocation.latitude);
      data.append('dropOffLong', extra.dropoffLocation.longitude);
      data.append('delivery_time', moment(selectedTime).format("hh:MM A"));
      data.append('pickup_country_code', deliveryData.code);
      data.append('dropoff_country_code', dropOffData.code);
      data.append('payment_method_id', confirm==="Efectivo" ? 1 : confirm === "PixelPay" ? 2 : 6);
      data.append('payment_order_id', confirm==="Efectivo" ? "" : orderID);
      
      Api.createOrder(data).then((response)=> {
         // console.log({response})
         if(response)
         {
           
            if(response.result === "false"){
               swal("Alerta",response.message, "error");
               setIsLoading (false);
            }else{
               swal({
                  title: "Alerta", 
                  text: response.message, 
                  icon: "success",
                  button: true,
               })
               .then((willDelete) => {
                  setIsLoading (false);
                  history.replace("/success")
               })
            }
         }else{
            swal("Alerta","erro inesperado")
            setIsLoading (false);
            //history.replace("/success") --> solo en debug
         }
      })
      .catch( (error)=> {
         swal("Alerta",error, "error");
         console.log(error);
         setIsLoading (false);
      });
   }
   const onLogin = (loginData) => {
      let FormData = require('form-data');
      let data = new FormData();
      data.append('password', loginData.password);
      data.append('device_token', '');
      data.append('device_type', 'android');
      data.append('email', loginData.email);

      if(loginData.email === "" || loginData.password ===""){
         swal("Alerta", "Todos los campos son necesarios", "error");
      }else{
        Api.login(data)
         .then((response)=> {
            if(response)
            {
               if(response.result === "false"){
                  swal("Alerta",response.message, "error");
               }else{
                  setSession(response.data)
                  setUserId(response.data.user_id)//set user id
                  stepper.nextStep();
               }
            }else{
               swal("Alerta","erro inesperado")
            }
         })
         .catch( (error)=> {
            swal("Alerta",error, "error");
            console.log(error);
         });
      }
   };

   const handleBlurSignUp = (e) => {
      const newLoginData = { ...signUpData };
      newLoginData[e.target.name] = e.target.value;
      setSignUpData(newLoginData);
  };

   const onSignUp = (signUpData) =>{
      let FormData = require('form-data');
      let data = new FormData();
      data.append('firstname', signUpData.name);
      data.append('lastname', signUpData.lastName);
      data.append('email', signUpData.email);
      data.append('phone', signUpData.number);
      data.append('password', signUpData.password);
      data.append('user_type', 1);
      data.append('vehicle_reg_no', "");
      data.append('dob', "");
      data.append('vehicle_type', "");
      data.append('device_token', "");
      data.append('house_no', signUpData.numberHome);
      data.append('street_name', signUpData.stName);
      data.append('country_name', signUpData.country);
      data.append('country_code', signUpData.number);
      data.append('social_type', "");
      data.append('social_id', "");
      data.append('state', signUpData.state);
      data.append('postcode', "");
      data.append('suburb', signUpData.city);
      data.append('company_name', signUpData.company);
      Api.signup(data).then((response)=> {
         // console.log(JSON.stringify(response));
         if(response)
         {
            if(response.result === "failure"){
               swal("Alerta",response.message, "error");
            }else{
               onLogin({email : signUpData.email , password: signUpData.password})
            }
         }else{
            swal("Alerta","erro inesperado")
         }
      })
      .catch( (error)=> {
         swal("Alerta",error, "error");
         console.log(error);
      });
   }



   const setSession = (authResult) => {
      // Set the time that the access token will expire at
      //5minutos
      let expiresAt = JSON.stringify((600000) + new Date().getTime());
      const userData = {
         companyName : authResult.company_name,
         countryCode : authResult.country_code,
         countryName : authResult.country_name,
         email : authResult.email,
         firstName: authResult.firstname,
         houseNo : authResult.house_no,
         lastName: authResult.lastname,
         phone: authResult.phone,
         streetName: authResult.street_name,
         state: authResult.state,
         suburb: authResult.suburb,
         postCode: authResult.postcode,
         userId: authResult.user_id,
         userType: authResult.user_type,
         profileImage: authResult.profile_image
      }
      localStorage.setItem('userData',  JSON.stringify(userData));
      localStorage.setItem('expires_at', expiresAt);
      setUserData(userData)
   };

   const [show, setShow] = useState(false);
   const handleClose = () => {
      setShow(false);
   };

   const handleShow = () => {
      setShow(true)
   };
   const [clickedPay, setClickedPay] = useState("");
   const [confirm, setConfirm]= useState("");
   const handlePay = (value) => {
     setClickedPay(value);
     setConfirm(value);
   };

   const makeid = (length) => {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
   }

   const confirmPixelPay = () =>{
      const hash = window.calcMD5(`${orderID}|${PixelConfig.PIXEL_KEY}|${PixelConfig.PIXEL_SECRET}`)
      //buscar transacciones en api
      let FormData = require('form-data');
      let data = new FormData();
      data.append("order_id",orderID)
      setIsLoading (true);
      Api.checkPixelPayment(data).then((response)=>{
         if(response && response.result){
            //pago aceptado guardar
            if(hash === response.data.payment_hash)
            {
               
               //pago aceptado guardar
               save();
               setOrderId("");
            }else{
               alert("Pago es inválido")
            }
         }else{
            alert(response.message)
         }
      }).catch((err)=>{
         alert("No se pudo verificar el pago, por favor intente más tarde")
      }).finally(()=>{
         setIsLoading (false);
      })
   }

   
   const confirmUsPay = () =>{
      //buscar transacciones en api
      setIsLoading (true);
      const data = {
         id: orderID
      }
      console.log({orderID})
      USPay.checkPay(data).then((response)=>{
         console.log(response)
         if(response && response.id){
            //pago aceptado guardar
            //pago aceptado guardar
            save();
         }else{
            alert("Pago es inválido")
         }
      }).catch((err)=>{
         alert("No se pudo verificar el pago, por favor intente más tarde")
      }).finally(()=>{
         setIsLoading (false);
      })
   }

   const confirmar = ()=>{
      switch (confirm) {
         case "Efectivo":
            save()
            break;
         case "PixelPay":
            const id = userId +"-"+ makeid(5)
            const data = {
               '_order_id': id,
               '_amount': parseFloat(extra.totalDeliveryCost).toFixed(2),
               '_first_name': userData.firstName,
               '_last_name': userData.lastName,
               '_email': userData.email
             }
             //console.log({data})
             setOrderId(id);
             PixelPay.getUrl(data).then((response)=>{
               //console.log(response)
               if(response && response.success)
               {
                 setPaymentClicked(1);
                 window.open(response.url,'_blank');
               }else{
                 alert("No se puede cargar la forma de pago.")
               }
             })
            break;
         case "Pagadito":
            Pagadito.connect().then((response)=>{
               console.log(response);
               if(response && response.code === "PG1001")
               {
                  const token = response.value;
                  const id = userId +"-"+ makeid(5)
                  setOrderId(token);
                  const details = [
                     {
                        quantity: 1,
                        description: "Pik Envio",
                        price: parseFloat(extra.totalDeliveryCost).toFixed(2),
                        url_product:"https://pikapphn.com"
                     }
                  ];
                  const data = {
                     token,
                     ern: id,
                     amount:parseFloat(extra.totalDeliveryCost).toFixed(2),
                     currency: 'HNL',
                     details: JSON.stringify(details)
                  }
                  Pagadito.exec_trans(data).then((res)=>{
                     console.log(res);
                     if(res && res.code === "PG1002"){
                        window.open(res.value,'_blank');
                     }else{
                       if(res) alert(res.message)
                       else alert("No se puede cargar la forma de pago.")
                     }
                  }).catch((err)=>{
                     alert("No se puede cargar la forma de pago.")
                  })
               }else{
                  alert("No se puede cargar la forma de pago.")
                }
            }).catch((err)=>{
               alert("Error obteniendo forma de pago.")
            })
            break;
         case "UsPay":
            const data_pay = {
               'asunto': "PEDIDO PIK/"+userData.email,
               'impuesto': 0,
               'moneda': "HNL",
               'monto': parseFloat(extra.totalDeliveryCost),
             }
             USPay.getUrl(data_pay).then((response)=>{
                console.log(response)
                if(response){
                  setPaymentClicked(1);
                  setOrderId(response.id);
                  window.open(response.url,'_blank');
                }else{
                  alert("No se ha podido crear el link. El monto supera el límite de transacción")
                }
             })
            break;
         default:
            break;
      }
   }

   return (
      <Fragment>
         <div className="row justify-content-center  align-items-center h-80">
            <div className="col-md-7">
               <div className="card">
                  <div className="card-header">
                     <div className="row align-items-center">
                        <h4 className="card-title text-primary mr-2">NUEVO</h4>
                        <img style={{borderRadius:10, height:40, widows:40}} className="" src={logo} alt="" />
                     </div>
                  </div>
                  <div className="card-body">
                     <div
                        onSubmit={(e) => e.preventDefault()}
                        id="step-form-horizontal"
                        className="step-form-horizontal"
                     >
                        <StepWizard nav={<Nav />} instance={(w) => setStepper(w)} isLazyMount>
                           <QuienEnvia handleBlur={handleBlur} deliveryData={deliveryData} />
                           <PikOrigen handleBlur={handleBlur} deliveryData={deliveryData} setPickupLocation={setPickupLocation} setDeliveryData={setDeliveryData} setValidArea={setValidArea}/>
                           <QueEnvias handleBlur={handleBlur} deliveryData={deliveryData}/>
                           <Vehiculo handleBlurStep2={handleBlurStep2} dropOffData={dropOffData} userId={userId}/>
                           <FechaEnvio handleBlur={handleBlur} deliveryData={deliveryData} selectedDate={selectedDate} selectedTime={selectedTime}  handleDateChange={handleDateChange}  handleTimeChange={handleTimeChange}/>
                           <Entrega handleBlurStep2={handleBlurStep2} dropOffData={dropOffData}/>
                           <Destino handleBlurStep2={handleBlurStep2} dropOffData={dropOffData} setDropoffLocation={setDropoffLocation} setDropOffData={setDropOffData} setValidArea={setValidArea}/>
                           {!userId && <Login onLogin={onLogin} handleBlur={handleBlurSignUp} signUpData={signUpData}/>}
                           <ConfirmPik deliveryData={deliveryData} userId={userId} dropOffData={dropOffData} 
                            selectedDate={selectedDate}  selectedTime={selectedTime} setExtra={setExtra} dropoffLocation={dropoffLocation} pickupLocation={pickupLocation} /> 
                        </StepWizard>
                        <Fragment>
                           {stepper && stepper.currentStep > 1 && <button style={prevStyle} onClick={()=> stepper.previousStep()}>Anterior</button>}
                           {stepper && stepper.currentStep < stepper.totalSteps && <button style={nextStyle} onClick={()=> nextStep()}>Siguiente</button>}
                           {stepper && stepper.currentStep === stepper.totalSteps && <button style={!extra.totalDeliveryCost || parseFloat(extra.totalDeliveryCost).toFixed(2)<=0 ? disableStyle : finishStyle} onClick={()=> handleShow()} disabled={!extra.totalDeliveryCost || parseFloat(extra.totalDeliveryCost).toFixed(2)<=0}>Pagar</button>}
                           {/* {stepper && stepper.currentStep === stepper.totalSteps && <button style={finishStyle} onClick={()=> save()}>Reservar</button>} */}
                        </Fragment>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
               <div className="row justify-content-start align-items-center">
                  <img style={{borderRadius:10, height:40, widows:40}} className="mr-2" src={logo} alt="" />
                  <h5 className="text-left text-primary mt-1">METODO DE PAGO</h5>
               </div>
            </Modal.Header>
            <Modal.Body>
               <p className="fs-14 mb-1">Seleccion un método de pago. </p>
               {
                  isLoading && <div>    <Spinner type="grow" color="primary" className="spinner-grow text-primary"/>
                  <Spinner type="grow" color="primary" className="spinner-grow text-primary"/>
                  <Spinner type="grow" color="primary" className="spinner-grow text-primary"/>
                  </div>
               }
               {
                  !isLoading && <div>
                     <div className="text-center mt-3 mb-3">
                        <Button variant={clickedPay === "Efectivo" ? "contained":null} color="primary" onClick={() => handlePay("Efectivo")}>
                           <img src={CashLogo} width={37.5} height={30} alt="cash-logo" style={{marginRight: 10}}/>
                           <span>Efectivo</span>
                        </Button>
                     </div>
                     <div className="text-center mb-3">
                        <Button variant={clickedPay === "PixelPay" ? "contained":null} color="primary" onClick={() => handlePay("PixelPay")}>
                           <img src={PixelPayLogo} width={135} height={30} alt="pixel-pay-logo"/>
                           <span>Tarjeta de Crédito/Debito</span>
                        </Button>
                     </div>
                     <div className="text-center mb-3">
                        <Button variant={clickedPay === "UsPay" ? "contained":null} color="primary" onClick={() => handlePay("UsPay")}>
                           <img src={UsPayLogo} width={135} height={30} style={{marginRight:10}} alt="us-pay-logo"/>
                           <span> Tarjeta de Crédito/Debito</span>
                        </Button>
                     </div>
                     
                  </div>
               }
              {!isLoading && <h4 className="text-black font-w600 mb-0 fs-22">Monto a Pagar: {parseFloat(extra.totalDeliveryCost).toFixed(2)} Lps.</h4>}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={handleClose} disabled={isLoading}>
                  Cancelar
               </Button>
               <Button variant="primary" onClick={confirmar} disabled={isLoading}>
                  Pagar
               </Button>
               {
                  (paymentClicked===1) && <Button onClick={confirm === "PixelPay" ? confirmPixelPay: confirmUsPay} disabled={isLoading}>
                     Confirmar Pago
                  </Button>
               }
               {
                  paymentClicked===2 && <Button onClick={confirmPixelPay} disabled={isLoading}>
                     Enviar pago a administrador.
                  </Button>
               }
            </Modal.Footer>
         </Modal>
      </Fragment>
   );
};

export default Delivery;
