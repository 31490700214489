import React, { useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import 'dayjs/locale/es'


function BasicDatePicker(props) {
   const [selectedDate, handleDateChange] = useState(new Date());

   return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
         <DatePicker
            locale="es"
            autoOk
            label=""
            clearable
            name={props.name}
            format="dd/MM/yyyy"
            disablePast
            value={props.selectedDate}
            onChange={props.handleDateChange}
            style={{borderWidth:0}}
         />
      </MuiPickersUtilsProvider>
   );
}

export default BasicDatePicker;
