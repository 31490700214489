import axios from 'axios'
import configApi from "./uspayConfig"
const client = axios.create({
    baseURL: configApi.USPAY_ENDPOINT,
    timeout: 60000,//1 minute
    headers: {'Content-Type': 'application/json'}
})

client.interceptors.request.use((config) => {
	return new Promise((resolve, reject) => {
        config.data ={
            ...config.data,
            'key_id': `${configApi.USPAY_KEY}`, 
            'secret_key': `${configApi.USPAY_SECRET}`,
        }
        // console.log(config)
        resolve(config)
	})
}, (error) => {
    console.log(error)
})

export default {
    getUrl (data,config={}) {
		return client.post(`/pagos`, data,config)
	},
    checkPay(data, config={}) {
        return client.post(`/pago`, data,config)
    }
}