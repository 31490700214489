import React, { Fragment, useState, useEffect } from "react";
import swal from "sweetalert";
import Api from "../../modules/api";
import history from "../../history";
import {Tabs,Tab} from 'react-bootstrap'
import OrderTable from "./OrderTable"

const Orders = () => {
    const [data, setData] = useState([]);
    const [logout, setLogout] = useState(false);
    const url = "https://api.pikapphn.com/image/"
    const [key, setKey] = useState('home');

    useEffect(() => {
        const userData = localStorage.getItem('userData')
        if(userData){
            const user = JSON.parse(userData); 
            if(key === "home")
            {
                Api.getUserDeliveries({userid : user.userId}).then((response)=>{
                    if(response && response.data && response.data.length>0){
                        setData(response.data);
                    }
                }).catch( (error)=> {
                  swal("Alerta",error, "error");
                  console.log(error);
                });
            }else{
                Api.customerOrderHistory({userid : user.userId})
                .then((response)=> {
                    if(response && response.data && response.data.length>0){
                        setData(response.data.reverse());
                    }
                })
                .catch( (error)=> {
                  swal("Alerta",error, "error");
                  console.log(error);
                });
            }

        }else{
            setLogout(true)
            swal({
                title: "Alerta", 
                text: "Para ver tus ordenes primero inicia sesión", 
                icon: "warning",
                buttons: [
                    "Cancelar","Ok"
                ],
             })
             .then((willDelete) => {
                if (willDelete) {
                    history.replace('login')
                  } else {
                  }
             })
        }
    },[key]);
 
   return (
      <Fragment>
         <div className="row">
			<div className="col-xl-12 col-xxl-12 col-lg-12 col-sm-12">
				<div className="card">
					<div className="card-body p-0">
                        <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                            >
                            <Tab eventKey="home" title={<h4 className="card-title mb-2">Pedidos Actuales</h4>}>
                            </Tab>
                            <Tab eventKey="profile" title={<h4 className="card-title mb-2">Pedidos Completados</h4>}>
                            </Tab>
                        </Tabs>
                        <OrderTable url={url} data={data} logout={logout}/>
					</div>
				</div>
			</div>
        </div>         
    </Fragment>
   );
};

export default Orders;
