import React, { useState } from "react";
import swal from "sweetalert";
import history from "../../history";
import Api from "../../modules/api";
import {Button} from "react-bootstrap"

const ForgotPassword = () => {

   const [forgotData, setForgotData] = useState({
      email:""
   });
   const [isLoading, setLoading] = useState(false);

   const handleBlur = (e) => {
      const newForgotData = { ...forgotData };
      newForgotData[e.target.name] = e.target.value;
      setForgotData(newForgotData);
   };

   const submitHandler = (e) => {
      e.preventDefault();
      
      let FormData = require('form-data');
      let data = new FormData();
      data.append('email', forgotData.email);

      if(forgotData.email === "" ){
         swal("Alerta", "Todos los campos son necesarios", "error");
      }else{
         setLoading(true);
        Api.forgot(data)
         .then((response)=> {
            if(response)
            {
               if(response.result === "false"){
                  swal("Alerta",response.message, "error");
               }else{
                  swal("Alerta",response.message, "success");
                  history.replace('/login');
               }
            }else{
               swal("Alerta","erro inesperado","error")
            }
         })
         .catch( (error)=> {
            swal("Alerta",error, "error");
            console.log(error);
         }).finally(()=>{
            setLoading(false);
         });
      }
   };

   return (
      <div className="row justify-content-center  align-items-center h-80">
         <div className="col-md-6">
            <div className="authincation-content">
               <div className="row no-gutters">
                  <div className="col-xl-12">
                     <div className="auth-form">
                        <h4 className="text-center mb-4">
                           Restablecer Contraseña
                        </h4>
                        <form
                           action=""
                           onSubmit={(e) => submitHandler(e)}
                        >
                           <div className="form-group">    
                              <label className="mb-1 text-primary">  
                                 <strong>
                                    Correo electrónico
                                 </strong> 
                              </label>
                              <input 
                                 type="email" 
                                 className="form-control text-primary" 
                                 name="email" 
                                 value={forgotData.email} 
                                 onChange={handleBlur}   
                              />
                           </div>
                           <div className="text-center">
                              <Button  
                                 type="submit"  
                                 className="btn btn-primary btn-block"  
                                 disabled={isLoading}
                              >
                                 {isLoading ? 'ENVIANDO…' : 'ENVIAR'}
                              </Button>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ForgotPassword;
