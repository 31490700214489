import React from "react";

const QueEnvias = (props) => {

   return (
      <section>
         <div className="text-center mb-3">
            <h5 className="text-center mb-4 text-primary">¿Qué Envías?</h5>
         </div>
        <div className="form-group">
            <input
                type="text"
                name="detailItem" 
                placeholder="Descripción del artículo"
                value={props.deliveryData.detailItem}
                className="form-control text-primary"
                onChange={props.handleBlur}
            />
        </div>
        <div className="row  justify-content-between">
            <div className="form-group col-xl-4">
                <input
                    type="number"
                    name="amount" 
                    placeholder="# de artículos"
                    value={props.deliveryData.amount}
                    className="form-control text-primary"
                    onChange={props.handleBlur}
                />
            </div>
        </div>
      </section>
   );
};

export default QueEnvias;
