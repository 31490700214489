import React from 'react';
/* eslint react/prop-types: 0 */
//import styles from './nav.less';

const Nav = (props) => {
    const dots = [];
    for (let i = 1; i <= props.totalSteps; i += 1) {
        const isActive = props.currentStep === i;
        
        dots.push((
            <li
                key={`step-${i}`}
                className={`${isActive ? 'go2478912419' : props.currentStep < i ? 'go2067797667' : 'go767851704'}`}
               
            ><span>{i}</span></li>
        ));
    }

    return (
        <div className="step-form-horizontal">
            {/* <ol >
                {dots}
            </ol> */}
        </div>
    );
};

export default Nav;