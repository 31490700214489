import React, { useState } from "react";
import { Link } from "react-router-dom";
import profile from "../../images/pik_principal.png";
import swal from "sweetalert";
import history from "../../history";
import Api from "../../modules/api"

const Login = () => {
   const [loginData, setLoginData] = useState({email:"", password:""});
   const handleBlur = (e) => {
      const newLoginData = { ...loginData };
      newLoginData[e.target.name] = e.target.value;
      setLoginData(newLoginData);
   };
  
   const submitHandler = (e) => {
      e.preventDefault();

      let FormData = require('form-data');
      let data = new FormData();
      data.append('password', loginData.password);
      data.append('device_token', '');
      data.append('device_type', 'android');
      data.append('email', loginData.email);

      if(loginData.email === "" || loginData.password ===""){
         swal("Alerta", "Todos los campos son necesarios", "error");
      }else{
        Api.login(data)
         .then((response)=> {
            console.log(response,"response aqui")
            if(response)
            {
               if(response.result === "false"){
                  swal("Alerta",response.message, "error");
               }else{
                  setSession(response.data)
                  history.replace('/');
               }
            }else{
               swal("Alerta","erro inesperado")
            }
         })
         .catch( (error)=> {
            swal("Alerta",error, "error");
            console.log(error);
         });
      }
   };

   const setSession = (authResult) => {
      // Set the time that the access token will expire at
      //5minutos
      let expiresAt = JSON.stringify((600000) + new Date().getTime());
      //console.log({authResult})
      const userData = {
         companyName : authResult.company_name,
         countryCode : authResult.country_code,
         countryName : authResult.country_name,
         email : authResult.email,
         firstName: authResult.firstname,
         houseNo : authResult.house_no,
         lastName: authResult.lastname,
         phone: authResult.phone,
         streetName: authResult.street_name,
         state: authResult.state,
         suburb: authResult.suburb,
         postCode: authResult.postcode,
         userId: authResult.user_id,
         userType: authResult.user_type,
         profileImage: authResult.profile_image
      }
      localStorage.setItem('userData',  JSON.stringify(userData));
      localStorage.setItem('expires_at', expiresAt);
   };


   return (
      <div className="row justify-content-center align-items-center h-80">
         <div className="col-md-5 col-xl-5 col-lg-5">
            <div className="form-group">
               <div className="profile-photo text-center">
                  <img src={profile}	className="img-fluid" alt="profile"	/>
               </div>     
            </div>  
            <div className="authincation-content">
               <div className="row no-gutters">
                  <div className="col-xl-12">
                     <div className="auth-form">
                        <h4 className="text-center mb-4 text-primary"> Inicie sesión en su cuenta  </h4>
                        <form  action=""  onSubmit={(e) => submitHandler(e)}  >
                           <div className="form-group">    
                              <label className="mb-1 text-primary">  <strong>Correo electrónico</strong> </label>
                              <input type="email" className="form-control text-primary" name="email" value={loginData.email}  onChange={handleBlur}   />
                           </div>
                           <div className="form-group">
                              <label className="mb-1 text-primary"> <strong>Contraseña</strong>  </label>
                              <input type="password" className="form-control text-primary" name="password" value={loginData.password}  onChange={handleBlur}  />
                           </div>
                           <div className="form-row d-flex justify-content-end mt-4 mb-2">
                              <div className="form-group">
                                 <Link  className="text-secondary "  to="/forgot"  > ¿Se te olvidó tu contraseña?  </Link>
                              </div>
                           </div>
                           <div className="text-center">
                              <button  type="submit"  className="btn btn-primary btn-block"  onClick={() => submitHandler} > Iniciar sesión </button>
                           </div>
                        </form>
                        <div className="new-account mt-3">
                           <p className="text-center text-primary"> ¿No tienes una cuenta?{" "}   <Link className="text-secondary" to="/signup">  Regístrate   </Link>  </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Login;
