export const colonias  = [
    { label: 'Ciudad Kennedy', value: 'Cd Kennedy', latlng: {lat:14.063182,lng:-87.182627}},
    { label: 'Residencial Plaza', value: 'Residencial Plaza', latlng: {lat:14.0634309,lng:-87.1882865}},
    { label: 'La Joya', value: 'La Joya', latlng: {lat:14.0657,lng:-87.1959779}},
    { label: 'Bernardo Dazzy', value: 'Bernardo Dazzy',latlng: {lat:14.0583765,lng:-87.1861668}},
    { label: 'Residencial Honduras', value: 'Residencial Honduras', latlng: {lat:14.0549422,lng:-87.1777689}},
    { label: 'Res.Lomas del Dorado', value: 'Res.Lomas del Dorado', latlng: {lat:14.05432,lng:-87.1762337}},
    { label: 'Residencial Venecia', value: 'Residencial Venecia', latlng: {lat:14.0406507,lng:-87.1826887}},
    { label: 'Residencial París', value: 'Residencial París',latlng: {lat:14.0402149,lng:-87.1776617}},
    { label: 'Colonia Jesus Aguilar Paz', value: 'Colonia Jesus Aguilar Paz',latlng: {lat:14.0495704,lng:-87.1846032}},
    { label: 'Hato de Enmedio', value: 'Hato de Enmedio',latlng: {lat:14.0663042,lng:-87.1664267}},
    { label: 'Colonia San Angel', value: 'Colonia San Angel',latlng: {lat:14.0722158,lng:-87.1823245}},
    { label: 'Residencial Guaymuras', value: 'Residencial Guaymuras',latlng: {lat:14.0670691,lng:-87.1762937}},
    { label: 'Colonia Miraflores', value: 'Colonia Miraflores',latlng: {lat:14.0727743,lng:-87.1972613}},
    { label: 'Villa Esmeralda', value: 'Villa Esmeralda',latlng: {lat:14.0745855,lng:-87.2003324}},
    { label: 'La Vega', value: 'La Vega',latlng: {lat:14.0652284,lng:-87.202376}},
    { label: 'Colonia Las Colinas', value: 'Colonia Las Colinas',latlng: {lat:14.0781369,lng:-87.192741}},
    { label: 'Colonia San Ignacio', value: 'Colonia San Ignacio',latlng: {lat:14.0774566,lng:-87.1815755}},
    { label: 'Colonia El Hogar', value: 'Colonia El Hogar',latlng: {lat:14.0805628,lng:-87.1918448}},
    { label: 'Colonia Loma Linda Norte', value: 'Colonia Loma Linda Norte',latlng: {lat:14.0842229,lng:-87.191578}},
    { label: 'Colonia Florencia Sur', value: 'Colonia Florencia Sur',latlng: {lat:14.0817197,lng:-87.1759286}},
    { label: 'Residencial San Juan', value: 'Residencial San Juan',latlng: {lat:14.0666658,lng:-87.1697562}},
    { label: 'Colonia Bella Oriente', value: 'Colonia Bella Oriente',latlng: {lat:14.0666658,lng:-87.1697562}},
    { label: 'Residencial El Trapiche', value: 'Residencial El Trapiche',latlng: {lat:14.0823459,lng:-87.1719807}},
    { label: 'Tres Caminos ', value: 'Tres Caminos',latlng: {lat:14.0827261,lng:-87.1872997}},
    { label: 'Colonia Luis Landa', value: 'Colonia Luis Landa',latlng: {lat:14.0819498,lng:-87.1861735}},
    { label: 'Villa Santa Cristina', value: 'Villa Santa Cristina',latlng: {lat:14.0727386,lng:-87.1879304}},
    { label: 'Residencial Villas Colonial', value: 'Residencial Villas Colonial',latlng: {lat:14.0876211,lng:-87.1560888}},
    { label: 'Residencial Zarahemla I', value: 'Residencial Zarahemla I',latlng: {lat:14.1048353,lng:-87.1638601}},
    { label: 'Residencial Zarahemla II', value: 'Residencial Zarahemla II',latlng: {lat:14.102953,lng:-87.1634734}},
    { label: 'Colonia Tepeyac', value: 'Colonia Tepeyac',latlng: {lat:14.0921064,lng:-87.1948023}},
    { label: 'Colonia Payaqui', value: 'Colonia Payaqui',latlng: {lat:14.0912364,lng:-87.1842292}},
    { label: 'Colonia Lomas del Mayab', value: 'Colonia Lomas del Mayab',latlng: {lat:14.0915135,lng:-87.1893991}},
    { label: 'Colonia Florencia Oeste', value: 'Colonia Florencia Oeste',latlng: {lat:14.0883662,lng:-87.1911511}},
    { label: 'Colonia Florencia Norte', value: 'Colonia Florencia Norte',latlng: {lat:14.0866395,lng:-87.1817824}},
    { label: 'Colonia Lomas del Guijarro', value: 'Colonia Lomas del Guijarro',latlng: {lat:14.0941333,lng:-87.1875387}},
    { label: 'Colonia Ruben Dario', value: 'Colonia Ruben Dario',latlng: {lat:14.0953543,lng:-87.1955639}},
    { label: 'Colonia Las Minitas', value: 'Colonia Las Minitas',latlng: {lat:14.0969722,lng:-87.1959572}},
    { label: 'Colonia Las Palomas', value: 'Colonia Las Palomas',latlng: {lat:14.09791,lng:-87.1901117}},
    { label: 'Barrio Pueblo Nuevo', value: 'Barrio Pueblo Nuevo',latlng: {lat:14.0988735,lng:-87.1837305}},
    { label: 'Residencial La Cumbre', value: 'Residencial La Cumbre',latlng: {lat:14.0970058,lng:-87.1824486}},
    { label: 'Colonia 21 de Octubre', value: 'Colonia 21 de Octubre',latlng: {lat:14.1076351,lng:-87.1758268}},
    { label: 'Colonia Los Girasoles', value: 'Colonia Los Girasoles',latlng: {lat:14.1073528,lng:-87.1822972}},
    { label: 'Colonia Bolivar', value: 'Colonia Bolivar',latlng: {lat:14.1089881,lng:-87.1869934}},
    { label: 'Colonia La Reforma', value: 'Colonia La Reforma',latlng: {lat:14.1063157,lng:-87.1939739}},
    { label: 'Colonia La Campaña', value: 'Colonia La Campaña',latlng: {lat:14.1070575,lng:-87.1865597}},
    { label: 'Colonia Lara', value: 'Colonia Lara',latlng: {lat:14.1049445,lng:-87.1846726}},
    { label: 'Colonia Los Próceres', value: 'Colonia Los Próceres',latlng: {lat:14.1026034,lng:-87.1766855}},
    { label: 'Hospital san felipe', value: 'Hospital san felipe', latlng: {lat:14.1037341,lng:-87.1872054}},
    { label: 'Colonia Palmira', value: 'Colonia Palmira',latlng: {lat:14.1026337,lng:-87.1972268}},
    { label: 'Barrio Guadalupe', value: 'Barrio Guadalupe',latlng: {lat:14.1007908,lng:-87.1971173}},
    { label: 'Barrio San Rafael', value: 'Barrio San Rafael',latlng: {lat:14.1020984,lng:-87.2019513}},
    { label: 'La Leona del Rincón', value: 'La Leona del Rincón',latlng: {lat:14.1145911,lng:-87.1751082}},
    { label: 'Colonia Alameda', value: 'Colonia Alameda',latlng: {lat:14.0903517,lng:-87.1978272}},
    { label: 'Villa Olimpica', value: 'Villa Olimpica',latlng: {lat:14.0895346,lng:-87.1700062}},
    { label: 'Colonia Universidad Norte', value: 'Colonia Universidad Norte',latlng: {lat:14.0919077,lng:-87.17013}},
    { label: 'Colonia Villa Centroamericana', value: 'Colonia Villa Centroamericana',latlng: {lat:14.0925628,lng:-87.1640146}},
    { label: 'Prados Universitarios', value: 'Prados Universitarios',latlng: {lat:14.0852203,lng:-87.1568808}},
    { label: 'Residencial El Molinon', value: 'Residencial El Molinon',latlng: {lat:14.1058856,lng:-87.1596164}},
    { label: 'Residencial El Sauce', value: 'Residencial El Sauce',latlng: {lat:14.0386506,lng:-87.2335418}},
    { label: 'Residencial Las Hadas', value: 'Residencial Las Hadas',latlng: {lat:14.0457965,lng:-87.2341051}},
    { label: 'Residencial Roble Oeste', value: 'Residencial Roble Oeste',latlng: {lat:14.0530688,lng:-87.2471309}},
    { label: 'Residencial Las Uvas', value: 'Residencial Las Uvas',latlng: {lat:14.0433045,lng:-87.2378997}},
    { label: 'Residencial Villas Del Real', value: 'Residencial Villas Del Real',latlng: {lat:14.0368511,lng:-87.2416659}},
    { label: 'Mirador de los Angeles', value: 'Mirador de los Angeles', latlng: {lat:14.0383004,lng:-87.2600893}},
    { label: 'Residencial Santa Cruz', value: 'Residencial Santa Cruz', latlng: {lat:14.053813,lng:-87.2669148}},
    { label: 'Residencial Los Hidalgos', value: 'Residencial Los Hidalgos', latlng: {lat:14.0467303,lng:-87.2608477}},
    { label: 'Residencial Los Eucaliptos', value: 'Residencial Los Eucaliptos', latlng: {lat:14.051479,lng:-87.2553196}},
    { label: 'Residencial La Arboleda',  value: 'Residencial La Arboleda', latlng: {lat:14.0473683,lng:-87.2657874}},
    { label: 'Residencial VillasToscana', value: 'Residencial VillasToscana',latlng: {lat:14.0423706,lng:-87.2474044}},
    { label: 'Hacienda Real', value: 'Hacienda Real', latlng: {lat:14.0419409,lng:-87.2704912}},
    { label: 'Residencial Las casitas', value: 'Residencial Las casitas', latlng: {lat:14.0527403,lng:-87.2674946}},
    { label: 'Residencial las Cascadas', value: 'Residencial las Cascadas', latlng: {lat:14.0536855,lng:-87.2345995}},
    { label: 'Residencial Villas Los Laureles', value: 'Residencial Villas Los Laureles', latlng: {lat:14.070162,lng:-87.2422869}},
    { label: 'Residencial Francisco Morazán', value: 'Residencial Francisco Morazán', latlng: {lat:14.0662303,lng:-87.2386014}},
    { label: 'Colonia Los Angeles', value: 'Colonia Los Angeles', latlng: {lat:14.05145,lng:-87.2296424}},
    { label: 'La Modelo', value: 'Colonia Modelo', latlng:{lat:14.0558667,lng:-87.2283201}},
    { label: 'Colonia America', value: 'Colonia America', latlng: {lat:14.0535808,lng:-87.228582}},
    { label: 'Residencial Los Robles', value: 'Residencial Los Robles', latlng: {lat:14.0553233,lng:-87.2387644}},
    { label: 'Colonia 15 de Septiembre', value: 'Colonia 15 de Septiembre', latlng: {lat:14.0593163,lng:-87.2294833}},
    { label: 'Colonia Ciudad Nueva', value: 'Colonia Ciudad Nueva', latlng: {lat:14.0635023,lng:-87.2435904}},
    { label: 'Portal del Bosque', value: 'Portal del Bosque', latlng: {lat:14.0594419,lng:-87.2440249}},
    { label: 'Lomas de Toncontin', value: 'Lomas de Toncontin', latlng: {lat:14.0414353,lng:-87.2258135}},
    { label: 'Altos de Toncontín', value: 'Altos de Toncontín', latlng: {lat:14.0400899,lng:-87.2281408}},
    { label: 'Residencial Concepción', value: 'Residencial Concepción', latlng: {lat:14.0286249,lng:-87.2265153}},
    { label: 'Colonia Satelite', value: 'Colonia Satelite', latlng: {lat:14.0463506,lng:-87.2121032}},
    { label: 'Cerro Grande Zona 4', value: 'Cerro Grande Zona 4', latlng: {lat:14.1236852,lng:-87.2234076,}},
    { label: 'El Hatillo', value: 'El Hatillo', latlng: {lat:14.1313687,lng:-87.1720985}},
    { label: 'Valle de Angeles', value: 'Valle de Angeles', latlng: {lat:14.1625942,lng:-87.0462655}},
    { label: 'Santa Lucia', value: 'Santa Lucia', latlng: {lat:14.115679,lng:-87.1146693}},
    { label: 'El picacho', value: 'El picacho', latlng: {lat:14.2604905,lng:-86.644029}},
    { label: 'Colonia Viera', value: 'Colonia Viera', latlng: {lat:14.1142535,lng:-87.1969917}},
    { label: 'Ciudad del Angel', value: 'Ciudad del Angel', latlng: {lat:14.1143201,lng:-87.2483106}},
    { label: 'Aqua Splash', value: 'Aqua Splash', latlng: {lat:14.1169304,lng:-87.2587946}},
    { label: 'Parque empresarial Tegucigalpa', value: 'Parque empresarial Tegucigalpa' , latlng: {lat:14.0486242,lng:-87.2031014}},
    { label: 'Universidad Nacional Autonoma de Honduras' , value: 'Universidad Nacional Autonoma de Honduras', latlng: {lat: 14.0842938, lng: -87.1669159}},
    { label: 'Centro comercial los castaños' , value: 'Centro comercial los castaños', latlng: {lat: 14.1006143, lng:-87.1896579}},
    { label: 'Centro Civico Gubernamental' , value: 'Centro Civico Gubernamental', latlng: {lat: 14.0884843, lng: -87.1915803}},
    { label: 'Hospital medical center' , value: 'Hospital medical center', latlng: {lat: 14.0955824, lng: -87.1925067}},
    { label: 'Centro comercial el dorado' , value: 'Centro comercial el dorado', latlng: {lat: 14.0999428, lng: -87.1838056}},
    { label: 'Colonia palmira' , value: 'Colonia palmira', latlng: {lat:14.1026337, lng: -87.1972268}},
    { label: 'Fuerza aerea Hondureña' , value: 'Fuerza aerea Hondureña', latlng: {lat: 14.0543869, lng: -87.2141432}},
    { label: 'Copeco' , value: 'Copeco', latlng: {lat:14.0614938, lng: -87.2737366}},
    { label: 'Instituto Nacional Para la Atención a Menores Infractores (INAMI)' , value: 'Instituto Nacional Para la Atención a Menores Infractores (INAMI)', latlng: {lat: 14.0895928, lng: -87.1852181}},
    { label: 'Secretaria de relaciones Exteriores' , value: 'Secretaria de relaciones Exteriores', latlng: {lat: 14.0712911, lng: -87.2034199}},
    { label: 'MALL MULTIPLAZA ' , value: 'MALL MULTIPLAZA', latlng: {lat: 14.0882014, lng: -87.185544}},
    { label: 'Arsa (Agencia de Regulación Sanitaria)' , value: 'Arsa (Agencia de Regulación Sanitaria)', latlng: {lat: 14.0642323, lng: -87.2226881}},
    { label: 'Colonia miramontes ' , value: 'Colonia miramontes ', latlng: {lat: 14.0850453,lng:-87.1988941}},
    { label: 'Colonia Humuya' , value: 'Colonia Humuya', latlng: {lat:14.0849334,lng:-87.2070803}},
    { label: 'El prado ' , value: 'El prado ', latlng: {lat: 14.0859857, lng:-87.207696}},
    { label: 'Centro comercial plaza miraflores' , value: 'Centro comercial plaza miraflores', latlng: {lat: 14.0772723,lng:-87.1850897}},
    { label: 'Barrio bolsa' , value: 'Barrio La Bolsa', latlng: {lat: 14.0908947,lng:-87.2066838}},
    { label: 'Barrio el manchen' , value: 'Barrio el manchen', latlng: {lat: 14.107884, lng: -87.1898849}},
    { label: 'Colonia Elvel' , value: 'Colonia Elvel', latlng: {lat: 14.0833441, lng: -87.1952775}},
    { label: 'Colonia Villas del Rio' , value: 'Colonia Villas del Rio', latlng: {lat: 14.1067238, lng: -87.1908827}},
    { label: 'Colonia San Carlos' , value: 'Colonia San Carlos', latlng: {lat: 14.1019552, lng: -87.1892448}},
    { label: 'Secretaria de Derechos Humanos' , value: 'Secretaria de Derechos Humanos', latlng: {lat: 14.0846091, lng: -87.1822197}},
    { label: 'Parque central de Honduras' , value: 'Parque central de Honduras', latlng: {lat: 14.1058462, lng: -87.2069514}},
 ];