import React from "react";
import {  Col } from "react-bootstrap"
const QuienEnvia = (props) => {

   return (
      <section>
         <div className="text-center mb-3">
            <h5 className="text-center mb-4 text-primary">¿Quién Envía?</h5>
         </div>
            <div className="form-group">
               <input
                  type="text"
                  name="company" 
                  placeholder="Nombre de la Empresa (Opcional)"
                  value={props.deliveryData.company}
                  className="form-control text-primary"
                  onChange={props.handleBlur}
               />
            </div>
            <div className="row justify-content-between">
               <div className="form-group col-xl-6">
                  <input
                     type="text"
                     name="name" 
                     placeholder="Nombre"
                     value={props.deliveryData.name}
                     className="form-control text-primary"
                     onChange={props.handleBlur}
                  />
               </div>
               <div className="form-group col-xl-6">
                  <input
                     type="text"
                     name="lastName" 
                     placeholder="Apellido"
                     value={props.deliveryData.lastName}
                     className="form-control text-primary"
                     onChange={props.handleBlur}
                  />
               </div>
            </div>
            <div className="row  justify-content-between">
               <Col xl={3} lg={3} md={3} sm={3} xs={3} className="form-group">
                  <input
                     type="number"
                     name="code" 
                     placeholder="Código"
                     value={props.deliveryData.code}
                     className="form-control text-primary"
                     onChange={props.handleBlur}
                  />
               </Col>
               <Col xl={9} lg={9} md={9} sm={9} xs={9} className="form-group">
                  <input
                     type="number"
                     name="number" 
                     placeholder="Número de teléfono móvil"
                     value={props.deliveryData.number}
                     className="form-control text-primary"
                     onChange={props.handleBlur}
                  />
               </Col>
            </div>
      </section>
   );
};

export default QuienEnvia;
