import React, { Fragment } from "react";
import logo from "../../images/logo.png";
import { Row, Card, Col, Button } from "react-bootstrap";

const Thankyou = (props) => {
    return (
        <Fragment>
            <Row>
                <Col xl="12">
                    <Card className="text-white bg-success">
                        <Card.Header className=" border-0 pb-0">
                            <Card.Title className="text-white">Gracias por utilizar <img style={{borderRadius:10, height:40, widows:40}} className="" src={logo} alt="" /></Card.Title>
                        </Card.Header>
                        <Card.Body className=" mb-0">
                            <Card.Text>
                                En un momento recibirás los detalles de tu pedido.
                            </Card.Text>
                            <Button
                                as="a"
                                variant="success light"
                                href={"/ordenes"}
                                className="btn-card mt-3"
                            >
                                Ver Ordenes
                            </Button>
                        </Card.Body>
                        <Card.Footer className=" bg-transparent border-0 text-white">
                            Pik es parte de GIPerez Group
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
}

export default Thankyou;