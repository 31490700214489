import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Camion from "../../../images/Camion.png";
import Carro from "../../../images/Carro.png";
import Camioneta from "../../../images/Camioneta.png";
import Motocicleta from "../../../images/Motocicleta.png";
import Api from "../../../modules/api";
import swal from "sweetalert";
import Loader from "react-loader-spinner";

const Vehiculo = (props) => {
   const [clickedValue, setClickedValue] = useState("");
   const [vehiclesType, setVehiclesType] = useState([]);
   const [loader, setLoader] = useState(true);
   const handleClick = (value) => {
         setClickedValue(value);
         props.dropOffData.type = value;
   };

   useEffect(() => {
      let FormData = require('form-data');
      let data = new FormData();
      data.append('user_id', props.userId);   
      // console.log(data.userid)
      Api.getVehicleType(data).then((response)=>{
         // console.log(response.data)
         if(response && response.data && response.data.length>0){
            setVehiclesType(response.data)
            setLoader(false);
         }else{
            swal("Alerta","Ocurrio un error inesperado, intente de nuevo", "error");
            setLoader(false);
         }
     }).catch( (error)=> {
       swal("Alerta","Ocurrio un error inesperado, intente de nuevo", "error");
       console.log(error);
     });
   },[])

   const getImage = ( name ) =>{
      switch(name){
         case "Carro":
            return Carro;
         case "Motocicleta":
            return Motocicleta;
         case "Camión":
            return Camion;
         case "Camioneta":
            return Camioneta;
      }
      return Carro;
   }

   return (
      <section className="text-center mb-3">
         <div className="text-center mb-3">
            <h5 className="text-center mb-4 text-primary">¿Qué Vehículo prefieres?</h5>
         </div>
         <ButtonGroup color="primary" aria-label="contained primary button group">
            {
               vehiclesType.map((item,index)=>{
                  return(
                     <div>
                        {loader ? <Loader
                           type="Puff"
                           color="#dcdcdc"
                           height={50}
                           width={50}
                           // timeout={3000} //3 secs
                        />:
                        <div onClick={() => handleClick(item.name)} className="text-center mb-3">
                           <div className="text-center mb-3">
                              <img style={{borderRadius:10, height:80, widows:80}} className="logo-abbr" src={getImage(item.name)} alt="" />
                           </div>
                           <div className="text-center">
                              <Button  variant={clickedValue === item.name ? "contained":null} color="primary" >{item.name}</Button>
                           </div>
                        </div>}
                     </div>
                  );
               })
            }
         </ButtonGroup>
      </section>
   );
};

export default Vehiculo;

