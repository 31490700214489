export const KEY_ID = 'vxjgilpzziyu'

export const SECRET_KEY = 'hjdaxhdrxowrpslvshtosggp'

export const ENDPOINT = "https://aqui-market-honduras.wl.r.appspot.com/api"

export default {
    USPAY_KEY: KEY_ID,
    USPAY_SECRET: SECRET_KEY,
    USPAY_ENDPOINT: ENDPOINT,
};