import axios from 'axios'
import configApi from "./pixelConfig"
var stringify = require('qs-stringify')
const client = axios.create({
    baseURL: configApi.PIXEL_ENDPOINT,
    timeout: 60000,//1 minute
    headers: {'Content-Type': 'x-www-form-urlencoded'}
})

client.interceptors.request.use((config) => {
	return new Promise((resolve, reject) => {
        config.data ={
            ...config.data,
            '_key': `${configApi.PIXEL_KEY}`, 
            '_cancel': `${configApi.PAGE_URL}/payment-cancel`,
            '_complete': `${configApi.PAGE_URL}/payment-received`,
            '_callback': "https://api.pikapphn.com/ApiController/check_pixel_pay",
            'json': true
        }
        config.data = stringify(config.data);
        resolve(config)
	})
}, (error) => {
})

export default {
    getUrl (data,config={
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }) {
    // /sandbox/hosted/payment
    // /hosted/payment/other
		return client.post(`/hosted/payment/other`, data,config)
	},
}