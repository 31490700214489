import { server } from './config'
import client from './client'
const TOKEN_APP = "PIKAPP-10092020";
export default {
    login (data, config = {}) {
		return client.post(`${server}/login`, data, config)
	},
	forgot (data, config = {}) {
		return client.post(`${server}/forgetPassword`, data, config)
	},
	signup (data, config = {}) {
		return client.post(`${server}/registration`, data, config)
	},
	logout (data, config = {}) {
		return client.post(`${server}/logout`, data, config)
	},
	customerOrderHistory (data, config = {}) {
		return client.get(`${server}/customerOrderHistory?userid=${data.userid}&code=${TOKEN_APP}`, data, config)
	},
	getUserDeliveries (data, config = {}) {
		return client.get(`${server}/get_user_deliveries?user_id=${data.userid}&code=${TOKEN_APP}`, data, config)
	},
	settings (config = {}) {
		return client.get(`${server}/getSettings?code=${TOKEN_APP}`, config)
	},
	editProfile (data, config = {}) {
		return client.post(`${server}/editProfile`, data, config)
	},
	createOrder (data, config = {}) {
		return client.post(`${server}/create_order`, data, config)
	},
	sendContact (data, config = {}) {
		return client.post(`${server}/contactForm`, data, config)
	},
	getVehicleType (data, config = {}) {
		return client.post(`${server}/getVehicleTypes`, data, config)
	},
	checkForgetTime (data, config = {}) {
		return client.get(`${server}/checkForgetTime?code=${TOKEN_APP}&user_id=${data.userid}`, data, config)
	},
	updatePassword (data, config = {}) {
		return client.post(`${server}/updatePassword`, data, config)
	},
	getDeliveryCancelReason (data, config = {}) {
		return client.get(`${server}/getDeliveryCancelReason?code=${TOKEN_APP}&user_id=${data.userid}`, data, config)
	},
	cancelDelivery (data, config = {}) {
		return client.post(`${server}/cancelDelivery`, data, config)
	},
	listrestrictedarea (data, config = {}) {
		return client.get(`${server}/listrestrictedarea?code=${TOKEN_APP}&user_id=${data.userid}`, data, config)
	},
	checkPixelPayment (data, config = {}) {
		return client.post(`${server}/verifyPixelPayment`, data, config)
	},
}