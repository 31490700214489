import axios from 'axios'
import configApi from "./pagaditoConfig"
var stringify = require('qs-stringify')
const client = axios.create({
    baseURL: configApi.PAGADITO_ENDPOINT,
    timeout: 60000,//1 minute
    headers: {'Content-Type': 'x-www-form-urlencoded'}
})

client.interceptors.request.use((config) => {
	return new Promise((resolve, reject) => {
        config.data ={
            ...config.data,
            'format_return': 'json'
        }
        config.data = stringify(config.data);
        console.log(config)
        resolve(config)
	})
}, (error) => {
})

export default {
    connect (config={
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }) {
        const data = {
            'wsk': configApi.WSK,
            'operation': 'f3f191ce3326905ff4403bb05b0de150',
            'uid': configApi.UID
        }
		return client.post(`/charges.php`, data,config)
	},
    exec_trans (data,config={
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }) {
          data.operation = '41216f8caf94aaa598db137e36d4673e'
		return client.post(`/charges.php`, data,config)
	},
    get_status (data,config={
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }) {
        data.operation = '0b50820c65b0de71ce78f6221a5cf876'
		return client.post(`/charges.php`, data,config)
	},
}