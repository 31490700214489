import React, {useState } from "react";
import swal from "sweetalert";
import history from "../../history";
import Api from "../../modules/api"
const SignUp = () => {
   const [signUpData, setSignUpData] = useState(
      {
         company:"",
         name:"",
         lastName:"",
         email:"", 
         code:"",
         number:"",
         password:"",
         confirPassword:"",
         numberHome:"",
         stName:"",
         city:"",
         state:"",
         country:"",
         imageProfile:""
      }
   );
   const handleBlur = (e) => {
      const newSignUpData = { ...signUpData };
      newSignUpData[e.target.name] = e.target.value;
      setSignUpData(newSignUpData);
   };
   const submitHandler = (e) => {
      e.preventDefault();
      let FormData = require('form-data');
      let data = new FormData();
      data.append('company_name', signUpData.company);
      data.append('firstname', signUpData.name);
      data.append('lastname', signUpData.lastName);
      data.append('email', signUpData.email);
      data.append('phone', signUpData.number);
      data.append('password', signUpData.password);
      data.append('user_type', 1);
      data.append('vehicle_reg_no', "");
      data.append('dob', "");
      data.append('vehicle_type', "");
      data.append('device_token', "");
      data.append('house_no', signUpData.numberHome);
      data.append('street_name', signUpData.stName);
      data.append('country_name', signUpData.country);
      data.append('country_code', signUpData.code);
      data.append('social_type', "");
      data.append('social_id', "");
      data.append('state', signUpData.state);
      data.append('postcode', "");
      data.append('suburb', signUpData.city);
      
      if(signUpData.name === "" || signUpData.lastName === "" || signUpData.email === "" || signUpData.code === "" || signUpData.number === "" || signUpData.password ==="" || signUpData.confirPassword ==="" || signUpData.numberHome ==="" || signUpData.stName ==="" || signUpData.city ==="" || signUpData.state ==="" ||signUpData.country ===""){
         console.log("Faltan datos")
         swal("Alerta", "Todos los campos son necesarios", "error");
      }else{
         Api.signup(data)
         .then((response)=> {
            //console.log(JSON.stringify(response));
            if(response)
            {
               if(response.result === "failure"){
                  swal("Alerta",response.message, "error");
               }else{
                  // setSession(response.data)
                  //console.log(JSON.stringify(signUpData));
                  console.log("Registro Exitoso")
                  swal("Alerta", "Registro exitoso", "success");
                  history.replace('/login');
               }
            }else{
               swal("Alerta","erro inesperado")
            }
         })
         .catch( (error)=> {
            swal("Alerta",error, "error");
            console.log(error);
         });
         console.log(JSON.stringify(data));
      }
   }
 
   return (
      <div className="row justify-content-around h-80">
         <div className="authincation-content">
            <div className="col-xl-12 auth-form">
               <div className="mb-3">
                  <h4 className="text-center mb-4 text-primary">Información personal</h4>
               </div>
               <form  action=""  onSubmit={(e) => submitHandler(e)}  >
                  <div className="form-group">
                     <input
                        type="text"
                        name="company" 
                        placeholder="Nombre de la Empresa"
                        value={signUpData.company}
                        className="form-control text-primary"
                        onChange={handleBlur}
                     />
                  </div>
                  <div className="row  justify-content-between">
                     <div className="form-group col-xl-6">
                        <input
                           type="text"
                           name="name" 
                           placeholder="Nombre"
                           value={signUpData.name}
                           className="form-control text-primary"
                           onChange={handleBlur}
                        />
                     </div>
                     <div className="form-group col-xl-6">
                        <input
                           type="text"
                           name="lastName" 
                           placeholder="Apellido"
                           value={signUpData.lastName}
                           className="form-control text-primary"
                           onChange={handleBlur}
                        />
                     </div>
                  </div>
                  <div className="form-group">
                     <input  
                        type="email" 
                        name="email" 
                        placeholder="Correo electrónico"
                        value={signUpData.email} 
                        className="form-control text-primary"   
                        onChange={handleBlur}   />
                  </div>
                  <div className="row  justify-content-between">
                     <div className="form-group col-xl-3">
                        <input
                           type="number"
                           name="code" 
                           placeholder="Código"
                           value={signUpData.code}
                           className="form-control text-primary"
                           onChange={handleBlur}
                        />
                     </div>
                     <div className="form-group col-xl-9">
                        <input
                           type="number"
                           name="number" 
                           placeholder="Número de teléfono móvil"
                           value={signUpData.number}
                           className="form-control text-primary"
                           onChange={handleBlur}
                        />
                     </div>
                  </div>
                  <div className="form-group">
                     <input 
                        type="password" 
                        name="password" 
                        placeholder="Contraseña"
                        value={signUpData.password}  
                        className="form-control text-primary" 
                        onChange={handleBlur}  />
                  </div>
                  <div className="form-group">
                     <input 
                        type="password" 
                        name="confirPassword" 
                        placeholder="Confirmar Contraseña"
                        value={signUpData.confirPassword} 
                        className="form-control text-primary"  
                        onChange={handleBlur}  />
                  </div>
                  <div className="mb-3">
                     <h4 className="text-center mb-4 text-primary">Dirección</h4>
                  </div>
                  <div className="row  justify-content-between">
                     <div className="form-group col-xl-6">
                        <input
                           type="number"
                           name="numberHome" 
                           placeholder="Número de casa"
                           value={signUpData.numberHome}
                           className="form-control text-primary "
                           onChange={handleBlur}
                        />
                     </div>
                     <div className="form-group col-xl-6">
                        <input  
                           type="text" 
                           name="stName" 
                           placeholder="Nombre de la calle"
                           value={signUpData.stName} 
                           className="form-control text-primary"   
                           onChange={handleBlur}   />
                     </div>
                  </div>
                  <div className="row justify-content-between">
                     <div className="form-group col-xl-6">
                        <input 
                           type="text" 
                           name="city" 
                           placeholder="Ciudad"
                           value={signUpData.city}  
                           className="form-control text-primary" 
                           onChange={handleBlur}  />
                     </div>
                     <div className="form-group col-xl-6">
                        <input 
                           type="text" 
                           name="state" 
                           placeholder="Estado"
                           value={signUpData.state} 
                           className="form-control text-primary"  
                           onChange={handleBlur}  />
                     </div>
                  </div>
                  <div className="form-group">
                     <input 
                        type="text" 
                        name="country" 
                        placeholder="País"
                        value={signUpData.country} 
                        className="form-control text-primary"  
                        onChange={handleBlur}  />
                  </div>
                  <div className="text-center">
                     <button  type="submit"  className="btn btn-primary btn-block"  onClick={() => submitHandler} > Registrar </button>
                  </div>
               </form>
            </div>
         </div>
      </div>  
   );
};

export default SignUp;
