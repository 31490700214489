import React, { Fragment, useEffect } from "react";

const Thankyou = () => {
    useEffect(()=>{
        const url_string = window.location.href;
        const url = new URL(url_string);
        const paymentHash = url.searchParams.get("paymentHash");
        const token = url.searchParams.get("token");
        if(paymentHash!==null)
        {
            localStorage.setItem('paymentReceived', paymentHash);
        }
        if(token!==null)
        {
            localStorage.setItem('pagaditoToken', token);
        }
        window.close();
    },[])
    return (
        <Fragment>
            <div>Redirigiendo espere...</div>
        </Fragment>
    );
}

export default Thankyou;