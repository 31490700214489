import React, { Fragment , useState} from "react"
import { Row , Col, Card , Button ,Form,Spinner  } from "react-bootstrap"
import Map from "../delivery/steps/MapPicker"
import Api from "../../modules/api"

const Contactus = () => {
    const [ contactData, setContactData ] = useState({
        nombre: "",
        correo: "",
        mensaje: ""
    })
    const [loading, isLoading] = useState(false)

    const handleSubmit = (e) =>{
        e.preventDefault();
        if(contactData.nombre === "" || contactData.correo === "" || contactData.mensaje === ""){
            alert("Por favor rellene todos los datos del formulario.")
        }else{
            isLoading(true);
            let FormData = require('form-data')
            let data = new FormData();
            data.append('nombre', contactData.nombre);
            data.append('correo', contactData.correo);
            data.append('mensaje', contactData.mensaje);
            Api.sendContact(data).then((response)=>{
                console.log(response)
                if(response && response.status === "success"){
                    alert(response.message);
                    clearData();
                }else{
                    alert("No se pudo enviar.")
                }
            }).finally(()=>{
                isLoading(false);
            })
        }
    }

    const handleBlur = (e) => {
        const newContactData = { ...contactData };
        newContactData[e.target.name] = e.target.value;
        setContactData(newContactData);
     };

     const clearData = ()=>{
        setContactData({
            nombre: "",
            correo: "",
            mensaje: ""
        })
     }

    return (
        <Fragment>
            <section>
                <div className="container">
                    <Row>
                        <Col lg={4} md={6} className="">
                            <Card>
                                <Card.Header>
                                    <Card.Title>Pik Contacto</Card.Title>
                                </Card.Header>
                                <Card.Body className="align-self-stretch">
                                    <p style={{textAlign: "justify"}}>Si tiene alguna pregunta, simplemente utilice los siguientes datos de contacto.</p>
                                    <ul>
                                        <li className="bx-wraper left m-b30">
                                            <div className="bx-xs border-1">
                                                <i className="ti-location-pin text-primary" style={{fontSize: 32}}></i>
                                            </div>
                                            <div style={{overflow: "hidden"}}>
                                                <h6 className="text-uppercase m-tb0 dez-tilte">Dirección:</h6>
                                                <p>Parque Empresarial Periférico, Nexxo Business Park Bodega #5</p>
                                            </div>
                                        </li>
                                        <li className="bx-wraper left m-b30">
                                            <div className="bx-xs border-1">
                                                <i className="ti-email text-primary" style={{fontSize: 32}}></i>
                                            </div>
                                            <div style={{overflow: "hidden"}}>
                                                <h6 className="text-uppercase m-tb0 dez-tilte">Correo Electronico:</h6>
                                                <a href="mailto:info@pikapphn.com"><p>info@pikapphn.com</p></a>
                                            </div>
                                        </li>
                                        <li className="bx-wraper left m-b30">
                                            <div className="bx-xs border-1">
                                                <i className="la la-whatsapp text-primary" style={{fontSize: 32}}></i>
                                            </div>
                                            <div style={{overflow: "hidden"}}>
                                                <h6 className="text-uppercase m-tb0 dez-tilte">Telefono:</h6>
                                                <a href="https://web.whatsapp.com/send?phone=+50433382350"><p>+504 3338-2350</p></a>
                                            </div>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6}>
                            <Card className="bg-secondary">
                                <Card.Header>
                                    <Card.Title className="text-white">Envíanos un Mensaje</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form onSubmit={handleSubmit} method="POST">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Tu Nombre" 
                                                name="nombre" 
                                                value={contactData.nombre}
                                                onChange={handleBlur}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control 
                                                type="email" 
                                                placeholder="Tu Correo" 
                                                name="correo" 
                                                value={contactData.correo}
                                                onChange={handleBlur}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control 
                                                as="textarea" 
                                                rows={4} 
                                                placeholder="Tu Mensaje" 
                                                name="mensaje"
                                                value={contactData.mensaje} 
                                                onChange={handleBlur}
                                            />
                                        </Form.Group>
                                        <Button color="primary" block type="submit" disabled={loading}>{
                                            !loading ? "Enviar": <Spinner animation="border" role="status" size="sm">
                                            <span className="sr-only">Enviando...</span>
                                          </Spinner>
                                        }</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={12} mb={30}>
                            <Map draggable={false} address="" height={'460px'}></Map>
                            <br/>
                            <p>v.b202201.573G</p>
                            {/* <p>v.b202110.D25NP7YMFY0T</p> */}
                        </Col>
                    </Row>
                </div>
            </section>
        </Fragment>
    );
}

export default Contactus;