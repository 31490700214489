export const KEY_ID = 'FH3053058411'//s4ndb0x

export const SECRET_KEY = '967512Oe-d135-493d-fb5a-6771a'//1234567890

export const ENDPOINT = "https://ficohsa.pixelpay.app"

export const PAGE_URL = "https://app.pikapphn.com"//http://localhost:3000

export default {
    PIXEL_KEY: KEY_ID,
    PIXEL_SECRET: SECRET_KEY,
    PIXEL_ENDPOINT: ENDPOINT,
    PAGE_URL: PAGE_URL
  };