import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
// import MetisMenu from "metismenujs";

class MM extends Component {
   // componentDidMount() {
   //    this.$el = this.el;
   //    this.mm = new MetisMenu(this.$el);
   // }
   // componentWillUnmount() {
   //    this.mm("dispose");
   // }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   
   constructor(props){
      super(props)
      this.state = {
         profileVisible:""
    }
 }
   /// Open menu
   componentDidMount() {
      // sidebar open/close
      const profileVisible = this.props.profileVisible
      this.setState({
         profileVisible
      })
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);
   }
   render() {
      /// Path
      const path = window.location.pathname;

      /// Active menu
      let crearOrden = [
         "",
      ],
      ordenes = [
         "ordenes",
      ],
      config = [ 
         "login",
         "signup"
      ]
      
      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">
                  <li
                     className={`${
                        crearOrden.includes(path.slice(1)) ? "mm-active" : ""
                     }`}
                  >
                     <Link
                        className="ai-icon"
                        to="/"
                     >
                        <i className="flaticon-381-notepad"></i>
                        <span className="nav-text">Crear Pedido</span>
                     </Link>
                  </li>
                  
                  <li
                     className={`${
                        ordenes.includes(path.slice(1)) ? "mm-active" : ""
                     }`}
                  >
                     <Link
                        className="ai-icon"
                        to="/ordenes"
                        
                     >
                        <i className="flaticon-381-list"></i>
                        <span className="nav-text">Mis Pedidos</span>
                     </Link>
                  </li>
                  {!this.props.profileVisible ?<li
                     className={`${
                        config.includes(path.slice(1)) ? "mm-active" : ""
                     }`}
                  >
                     <Link
                        className="has-arrow ai-icon"
                        to="#"
                        
                     >
                        <i className="flaticon-381-user"></i>
                        <span className="nav-text forms">Autenticación</span>
                     </Link>
                     <ul >
                        <li>
                           <Link to="/login">Iniciar Sesión</Link>
                        </li>
                        <li>
                           <Link to="/signup">Registrar</Link>
                        </li>
                     </ul>
                  </li>:null}
                  <li>
                     <Link className="ai-icon" to="/contact"> 
                        <i className="flaticon-381-phone-call"></i>
                        <span className="nav-text forms">Contáctanos</span>
                     </Link>
                  </li>

               </MM>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default SideBar;
