import React, { useState } from "react";
import { Link } from "react-router-dom";

const Login = (props) => {
   const [loginData, setLoginData] = useState({email:"", password:""});

   const handleBlur = (e) => {
      const newLoginData = { ...loginData };
      newLoginData[e.target.name] = e.target.value;
      setLoginData(newLoginData);
   };

   return (
    <section>
            <div >
               <div className="row no-gutters">
                  <div className="col-xl-12">
                     <div className="auth-form">
                        <h4 className="text-center mb-4 text-primary"> Inicie sesión en su cuenta  </h4>
                        <div>
                           <div className="form-group">    
                              <label className="mb-1 text-primary">  <strong>Correo electrónico</strong> </label>
                              <input type="email" className="form-control text-primary" name="email" value={loginData.email}  onChange={handleBlur}   />
                           </div>
                           <div className="form-group">
                              <label className="mb-1 text-primary"> <strong>Contraseña</strong>  </label>
                              <input type="password" className="form-control text-primary" name="password" value={loginData.password}  onChange={handleBlur}  />
                           </div>
                           <div className="form-row d-flex justify-content-end mt-4 mb-2">
                              <div className="form-group">
                                 <Link target="_blank" className="text-secondary "  to="/forgot"  > ¿Se te olvidó tu contraseña?  </Link>
                              </div>
                           </div>
                           <div className="text-center">
                              <button  type="submit"  className="btn btn-primary btn-block"  onClick={() => props.onLogin(loginData)} > Iniciar sesión </button>
                           </div>
                        </div>
                        <div className="new-account mt-3">
                           <p className="text-center text-primary"> ó </p>
                        </div>
                        <div >
                            <h4 className="text-center mb-4 text-primary"> Registrate  </h4>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="company" 
                                    placeholder="Nombre de la Empresa"
                                    value={props.signUpData.company}
                                    className="form-control text-primary"
                                    onChange={props.handleBlur}
                                />
                            </div>
                            <div className="row  justify-content-between">
                                <div className="form-group col-xl-6">
                                    <input
                                    type="text"
                                    name="name" 
                                    placeholder="Nombre"
                                    value={props.signUpData.name}
                                    className="form-control text-primary"
                                    onChange={props.handleBlur}
                                    />
                                </div>
                                <div className="form-group col-xl-6">
                                    <input
                                    type="text"
                                    name="lastName" 
                                    placeholder="Apellido"
                                    value={props.signUpData.lastName}
                                    className="form-control text-primary"
                                    onChange={props.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <input  
                                    type="email" 
                                    name="email" 
                                    placeholder="Correo electrónico"
                                    value={props.signUpData.email} 
                                    className="form-control text-primary"   
                                    onChange={props.handleBlur}   />
                            </div>
                            <div className="row  justify-content-between">
                                <div className="form-group col-xl-3">
                                    <input
                                    type="number"
                                    name="code" 
                                    placeholder="Código"
                                    value={props.signUpData.code}
                                    className="form-control text-primary"
                                    onChange={props.handleBlur}
                                    />
                                </div>
                                <div className="form-group col-xl-9">
                                    <input
                                    type="number"
                                    name="number" 
                                    placeholder="Número de teléfono móvil"
                                    value={props.signUpData.number}
                                    className="form-control text-primary"
                                    onChange={props.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <input 
                                    type="password" 
                                    name="password" 
                                    placeholder="Contraseña"
                                    value={props.signUpData.password}  
                                    className="form-control text-primary" 
                                    onChange={props.handleBlur}  />
                            </div>
                            <div className="form-group">
                                <input 
                                    type="password" 
                                    name="confirPassword" 
                                    placeholder="Confirmar Contraseña"
                                    value={props.signUpData.confirPassword} 
                                    className="form-control text-primary"  
                                    onChange={props.handleBlur}  />
                            </div>
                            <div className="mb-3">
                                <h4 className="text-center mb-4 text-primary">Dirección</h4>
                            </div>
                            <div className="row  justify-content-between">
                                <div className="form-group col-xl-6">
                                    <input
                                    type="number"
                                    name="numberHome" 
                                    placeholder="Número de casa"
                                    value={props.signUpData.numberHome}
                                    className="form-control text-primary "
                                    onChange={props.handleBlur}
                                    />
                                </div>
                                <div className="form-group col-xl-6">
                                    <input  
                                    type="text" 
                                    name="stName" 
                                    placeholder="Nombre de la calle"
                                    value={props.signUpData.stName} 
                                    className="form-control text-primary"   
                                    onChange={props.handleBlur}   />
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="form-group col-xl-6">
                                    <input 
                                    type="text" 
                                    name="city" 
                                    placeholder="Ciudad"
                                    value={props.signUpData.city}  
                                    className="form-control text-primary" 
                                    onChange={props.handleBlur}  />
                                </div>
                                <div className="form-group col-xl-6">
                                    <input 
                                    type="text" 
                                    name="state" 
                                    placeholder="Estado"
                                    value={props.signUpData.state} 
                                    className="form-control text-primary"  
                                    onChange={props.handleBlur}  />
                                </div>
                            </div>
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    name="country" 
                                    placeholder="País"
                                    value={props.signUpData.country} 
                                    className="form-control text-primary"  
                                    onChange={props.handleBlur}  />
                            </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
      </section>
   );
};

export default Login;
