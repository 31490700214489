export const uid = 'd9a34af166fcc0e65bcc29c21aa2df87'

export const wsk = '5ecc45bd3abf0d2a057f4b511e353936'

export const ENDPOINT = "https://sandbox.pagadito.com/comercios/apipg"


export default {
    UID: uid,
    WSK: wsk,
    PAGADITO_ENDPOINT: ENDPOINT,
  };