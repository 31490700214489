import React from "react";
import TextField from '@material-ui/core/TextField';
import moment from "moment"
function BasicTImePicker(props) {
   //const [selectedDate, handleDateChange] = useState(new Date());

   return (
         <TextField
            id="time"
            label=""
            type="time"
            v-model="hours"
            defaultValue={moment(props.selectedDate).format("HH:mm")}
            value={moment(props.selectedDate).format("HH:mm")}
            onChange={props.handleDateChange}
         />
   );
}

export default BasicTImePicker;
